export const getFileNameFromHeaders = (headers: any): string => {
  const contentDispositionHeaderName = "content-disposition";
  const contentDisposition = headers[contentDispositionHeaderName] as string;
  // @ts-ignore: Unreachable code error
  if (!contentDisposition) return undefined;

  const phraseBeforeAppearanceFilename = "filename=";
  return contentDisposition.slice(
    contentDisposition.indexOf(phraseBeforeAppearanceFilename) + 9
  );
};
