import React, { createContext, useReducer } from "react";
import {
  LeftSidebarAction,
  LeftSidebarState,
  leftSidebarReducer,
} from "./LeftSidebarReducer";

const LeftSidebarContext = createContext<
  | {
      state: LeftSidebarState;
      dispatch: React.Dispatch<LeftSidebarAction>;
    }
  | undefined
>(undefined);

export const useLeftSidebarContext = () => {
  const context = React.useContext(LeftSidebarContext);

  if (!context) {
    throw new Error("Out of context left sidebar");
  }
  return context;
};

interface UserContextProviderProps {
  children: React.ReactNode;
}

export const LeftSidebarContextProvider: React.FC<UserContextProviderProps> = (
  props
) => {
  const [state, dispatch] = useReducer(leftSidebarReducer, {
    isVisible: false,
    content: undefined,
  });

  return (
    <LeftSidebarContext.Provider value={{ state, dispatch }}>
      {props.children}
    </LeftSidebarContext.Provider>
  );
};
