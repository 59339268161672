import { Spin } from "antd";
import { useTranslation } from "react-i18next";
import { tKeys } from "../../Constant/tKeys";
import { useGlobalContext } from "../../GlobalContextProvider/GlobalContextProvider";

export const AppVersion: React.FC = () => {
  const { appVersion } = useGlobalContext();
  const { t } = useTranslation();
  return (
    <Spin spinning={!!!appVersion}>
      {appVersion && (
        <>
          {t(tKeys.version, tKeys.version)}: {appVersion}
        </>
      )}
    </Spin>
  );
};
