import { useQuery } from "react-query";
import axios from "../../Shared/axios";
import { PageAble } from "../Invoices/Models/InvoicesTypes";
import { CompanyBasicInfo } from "../Settings/Users/Forms/Shared/BasedUserForm/BasicUserFormHooks/useGetCompanies";
import { Company } from "./Models/Company";
import { SettingsIncomingInvoicesType } from "./Models/CompanyAdditionalSettings";
import {
  IncomingInvoiceBrand,
  InvoiceBrand,
} from "./Models/InvoiceDesignModel";
export const entitiesUrl = "api/companies";

export class EntitiesService {
  static getAll() {
    return axios.get<{ content: CompanyBasicInfo[] }>(`${entitiesUrl}`);
  }

  static get(pageAble: PageAble) {
    return axios.get<any[]>(`${entitiesUrl}`, {
      params: pageAble,
    });
  }

  static getCompany(
    id: string | number
  ): { data: any } | PromiseLike<{ data: any }> {
    return axios.get<Company>(`${entitiesUrl}/${id}`);
  }

  static delete(id: string | number) {
    return axios.delete(`${entitiesUrl}/${id}`);
  }

  static create(company: Company) {
    return axios.post(`${entitiesUrl}`, company);
  }

  static update(company: Company) {
    return axios.put(`${entitiesUrl}/${company.id}`, company);
  }

  static updateAdditionalSetting(
    companyId: string,
    additionalSettings: SettingsIncomingInvoicesType
  ) {
    return axios.put<SettingsIncomingInvoicesType>(
      `${entitiesUrl}/${companyId}/incoming-invoice-configuration`,
      additionalSettings
    );
  }

  static getAdditionalSetting(companyId: string) {
    return axios.get<SettingsIncomingInvoicesType>(
      `${entitiesUrl}/${companyId}/incoming-invoice-configuration`
    );
  }

  static getCompanyBrand(companyId: string) {
    return axios.get<IncomingInvoiceBrand>(`${entitiesUrl}/${companyId}/brand`);
  }

  static updateInvoiceBrand(companyId: string, invoiceBrand: InvoiceBrand) {
    const formData = new FormData();
    if (invoiceBrand.companyLogo) {
      formData.append("companyLogo", invoiceBrand.companyLogo);
    }
    formData.append("themeColor", invoiceBrand.themeColor);
    return axios.put(`${entitiesUrl}/${companyId}/brand`, formData);
  }

  static deleteCompanyLogo(companyId: string) {
    return axios.delete<IncomingInvoiceBrand>(
      `${entitiesUrl}/${companyId}/brand`
    );
  }
}

export const useGetCompany = (companyId: number) =>
  useQuery(
    ["company", companyId],
    async () => {
      return await EntitiesService.getCompany(companyId as any);
    },
    { enabled: !!companyId, refetchOnMount: true, cacheTime: 1000 }
  );
