import { useQueryClient } from "react-query";
import { useLocation, useNavigate } from "react-router";
import { Option, Select } from "../../../../DesignSystem";
import { useUserContext } from "../../../UserContextProvider/UserContextProvider";

export const entitySelected = "entitySelected";
export const entityObject = "entityObject";

interface CompanySelectProps {
  moduleName: string;
}

const CompanySelect: React.FC<CompanySelectProps> = (props) => {
  const { user } = useUserContext();
  const location = useLocation();
  const navigate = useNavigate();
  const urlParts = location.pathname.split("/");
  const companyId = urlParts[2];

  const getSelectedCompanyName = () =>
    user?.detailsOfAssignedCompanies?.find((x: any) => x.id == companyId)?.name;

  const queryClient = useQueryClient();
  const onChange = (e: string) => {
    if (!!user) {
      localStorage.setItem(user.username, e);
    }
    navigate(`/${props.moduleName}/${e}/${urlParts[urlParts.length - 1]}`);
    queryClient.invalidateQueries();
  };

  return (
    <>
      <Select
        allowClear={false}
        isInTable
        showSearch
        optionFilterProp="children"
        value={getSelectedCompanyName()}
        onChange={onChange}
        data-testid={"company-selector"}
      >
        {user?.detailsOfAssignedCompanies?.map(
          (company: { id: number; name: string | undefined }) => (
            <Option value={company.id} key={company.id}>
              <span data-testid={`company-${company.name}`}>
                {company.name}
              </span>
            </Option>
          )
        )}
      </Select>
    </>
  );
};

export default CompanySelect;
