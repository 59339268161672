import React, { ReactNode, useMemo } from "react";
import { Modal as AntModal, ModalProps as AntModalProps } from "antd";
import { v4 as uuid } from "uuid";
import { useTranslation } from "react-i18next";
import "./Modal.less";
import Button from "../Button/Button";

export enum ModalSizes {
  DEFAULT = 520,
  SMALL = 450,
  MEDIUM = 631,
  LARGE = 800,
  XLARGE = 1700,
}
interface Props extends AntModalProps {
  hideCancelButton: boolean;
  okDisabled?: boolean;
  okIsLoading?: boolean;
  okButtonType?: "link" | "text" | "default" | "ghost" | "primary" | "dashed";
  cancelButtonType?:
    | "link"
    | "text"
    | "default"
    | "ghost"
    | "primary"
    | "dashed";
  footerLeft?: ReactNode;
  cancelButtonClassName?: string;
  className?: string;
}

const Modal: React.FunctionComponent<Props> = (props) => {
  const containerId = useMemo(() => uuid(), []);

  const renderFooter = () => {
    const { t } = useTranslation();
    const {
      onCancel,
      cancelText,
      onOk,
      okText,
      footer,
      okDisabled,
      okIsLoading,
    } = props;

    if (footer) return footer;

    if (!onCancel && !onOk) return null;

    const tempFooter = [];

    if (onCancel && !props.hideCancelButton) {
      tempFooter.push(
        <Button
          key="footer_button_cancel"
          type={props.cancelButtonType ?? "text"}
          onClick={onCancel}
          className={`cancel-footer-button ${props.cancelButtonClassName}`}
        >
          {cancelText || t("button.cancel", "Cancel")}
        </Button>
      );
    }

    if (onOk && onOk !== null) {
      tempFooter.push(
        <Button
          key="footer_button_OK"
          className="ok-footer-button"
          type={props.okButtonType ?? "text"}
          onClick={onOk}
          data-testid={"modal-ok-button"}
          disabled={okDisabled}
          loading={okIsLoading}
        >
          {okText || "OK"}
        </Button>
      );
    }
    if (!!props.footerLeft) {
      tempFooter.unshift(props.footerLeft);
    }
    return tempFooter;
  };

  return (
    <>
      <AntModal
        key={containerId}
        className={props.className}
        width={ModalSizes.DEFAULT}
        {...props}
        footer={renderFooter()}
      />
    </>
  );
};

export default Modal;
