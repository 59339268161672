import Icon from "@ant-design/icons";
import { SvgPath } from "./SvgPath";

const SvgIcon: React.FC<any> = (props) => {
  const {
    path,
    g,
    transform,
    name,
    width = "1.2em",
    height = "1.2em",
    viewBox = "0 0 32 32",
    ...restProps
  } = props;
  return (
    <Icon
      {...restProps}
      component={() => (
        <svg
          width={width}
          height={height}
          fill="currentColor"
          fillRule={restProps?.fillRule || "evenodd"}
          viewBox={viewBox}
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
        >
          {g ? (
            g
          ) : (
            <g id={name}>
              <path d={path} transform={transform}></path>
            </g>
          )}
        </svg>
      )}
    />
  );
};

export const AppIcons = {
  Alert: (props: any) => (
    <SvgIcon {...props} {...SvgPath.Alert} name={"Alert"} />
  ),
  ArrowLeft: (props: any) => (
    <SvgIcon {...props} {...SvgPath.ArrowLeft} name={"AppArrowLeft"} />
  ),
  ArrowLeftFill: (props: any) => (
    <SvgIcon {...props} {...SvgPath.ArrowLeftFill} name={"ArrowLeftFill"} />
  ),
  ArrowRight: (props: any) => (
    <SvgIcon {...props} {...SvgPath.ArrowRight} name={"ArrowRight"} />
  ),
  VerticalMore: (props: any) => (
    <SvgIcon {...props} {...SvgPath.VerticalMore} name={"VerticalMore"} />
  ),
  OpenFolder: (props: any) => (
    <SvgIcon {...props} {...SvgPath.OpenFolder} name={"OpenFolder"} />
  ),
  Xml: (props: any) => <SvgIcon {...props} {...SvgPath.Xml} name={"Xml"} />,
  Pdf: (props: any) => <SvgIcon {...props} {...SvgPath.Pdf} name={"Pdf"} />,
  Delete: (props: any) => (
    <SvgIcon {...props} {...SvgPath.Delete} name={"Delete"} />
  ),
  Audience: (props: any) => (
    <SvgIcon {...props} {...SvgPath.Audience} name={"Audience"} />
  ),
  Edit: (props: any) => <SvgIcon {...props} {...SvgPath.Edit} name={"Edit"} />,
  CheckMarkFill: (props: any) => (
    <SvgIcon {...props} {...SvgPath.CheckMarkFill} name={"CheckMarkFill"} />
  ),
  CircleCheckmarkFill: (props: any) => (
    <SvgIcon
      {...props}
      {...SvgPath.CircleCheckmarkFill}
      name={"CircleCheckmarkFill"}
    />
  ),
  Comment: (props: any) => (
    <SvgIcon
      {...props}
      {...SvgPath.Comment}
      name={"Comment"}
      viewBox="0 0 20 20"
    />
  ),
  Toggle: (props: any) => (
    <SvgIcon {...props} {...SvgPath.Toggle} name={"Toggle"} />
  ),
  HelpQuestion: (props: any) => (
    <SvgIcon {...props} {...SvgPath.HelpQuestion} name={"HelpQuestion"} />
  ),
  HorizontalMore: (props: any) => (
    <SvgIcon {...props} {...SvgPath.HorizontalMore} name={"HorizontalMore"} />
  ),
  BoxOpenOutline: (props: any) => (
    <SvgIcon {...props} {...SvgPath.BoxOpenOutline} name={"BoxOpenOutline"} />
  ),
  UpChevron: (props: any) => (
    <SvgIcon {...props} {...SvgPath.UpChevron} name={"UpChevron"} />
  ),
  DownChevron: (props: any) => (
    <SvgIcon {...props} {...SvgPath.DownChevron} name={"DownChevron"} />
  ),
  Transform: (props: any) => (
    <SvgIcon {...props} {...SvgPath.Transform} name={"Transform"} />
  ),
  Hand: (props: any) => <SvgIcon {...props} {...SvgPath.Hand} name={"Hand"} />,
  Time: (props: any) => <SvgIcon {...props} {...SvgPath.Time} name={"Time"} />,
  TimeFill: (props: any) => (
    <SvgIcon {...props} {...SvgPath.TimeFill} name={"TimeFill"} />
  ),
  Workflow: (props: any) => (
    <SvgIcon {...props} {...SvgPath.Workflow} name={"Workflow"} />
  ),
  Tag: (props: any) => <SvgIcon {...props} {...SvgPath.Tag} name={"Tag"} />,
  Search: (props: any) => (
    <SvgIcon {...props} {...SvgPath.Search} name={"Search"} />
  ),
  SearchFill: (props: any) => (
    <SvgIcon {...props} {...SvgPath.SearchFill} name={"SearchFill"} />
  ),
  CircleCheckmark: (props: any) => (
    <SvgIcon {...props} {...SvgPath.CircleCheckmark} name={"CircleCheckmark"} />
  ),
  CircleMinusFill: (props: any) => (
    <SvgIcon {...props} {...SvgPath.CircleMinusFill} name={"CircleMinusFill"} />
  ),
  Info: (props: any) => <SvgIcon {...props} {...SvgPath.Info} name={"Info"} />,
  InfoOutline: (props: any) => <SvgIcon viewBox="0 0 16 16" {...props} {...SvgPath.InfoOutline} name={"InfoOutline"} />,
  EmailFill: (props: any) => (
    <SvgIcon {...props} {...SvgPath.EmailFill} name={"EmailFill"} />
  ),

  Email: (props: any) => (
    <SvgIcon {...props} {...SvgPath.Email} name={"Email"} />
  ),
  ConfirmedFill: (props: any) => (
    <SvgIcon {...props} {...SvgPath.ConfirmedFill} name={"ConfirmedFill"} />
  ),
  PaperAirplaneFill: (props: any) => (
    <SvgIcon
      {...props}
      {...SvgPath.PaperAirplaneFill}
      name={"PaperAirplaneFill"}
    />
  ),
  Share: (props: any) => (
    <SvgIcon {...props} {...SvgPath.Share} name={"Share"} />
  ),
  Close: (props: any) => (
    <SvgIcon {...props} {...SvgPath.Close} name={"Close"} />
  ),
  Revert: (props: any) => (
    <SvgIcon {...props} {...SvgPath.Revert} name={"Revert"} />
  ),
  AccountConvert: (props: any) => (
    <SvgIcon {...props} {...SvgPath.AccountConvert} name={"AccountConvert"} />
  ),

  Document: (props: any) => (
    <SvgIcon {...props} {...SvgPath.Document} name={"Document"} />
  ),
  Documents: (props: any) => (
    <SvgIcon {...props} {...SvgPath.Documents} name={"Documents"} />
  ),
  Lock: (props: any) => (
    <SvgIcon {...props} {...SvgPath.Lock} viewBox="0 0 12 12" name={"Lock"} />
  ),
  FolderOpenedFill: (props: any) => (
    <SvgIcon
      {...props}
      {...SvgPath.FolderOpenedFill}
      name={"FolderOpenedFill"}
    />
  ),
};
