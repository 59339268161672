import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { NavbarService } from "../../../Navbar/Navbar.service";
import { EndSessionModal } from "./EndSessionModal/EndSessionModal";
import SessionTimeout from "./SessionTimeout";
import { TokenRefresher } from "./TokenRefresher";

export const idleTimeSec = 13 * 60;

export enum LogoutStates {
  endSession = "endSession",
  logOut = "logOut",
  logIn = "logIn",
}
const SessionTimeoutManager = () => {
  const [showTimer, setShowTimer] = useState(false);
  let navigate = useNavigate();
  const logOut = () => {
    NavbarService.logout()
      .then(() => {
        navigate("/login");
      })
      .catch(() => {
        navigate("/login");
      });
  };

  useEffect(() => {
    const checkTimeout = () => {
      const lastActivity = localStorage.getItem("lastActivity");
      if (lastActivity) {
        const currentTime = new Date().getTime();
        const elapsedSec = (currentTime - Number(lastActivity)) / 1000;
        if (elapsedSec >= idleTimeSec) {
          setShowTimer(true);
        } else {
          setShowTimer(false);
        }
      }
    };

    const handleStorageChange = (e: any) => {
      if (e.key === "lastActivity") {
        checkTimeout();
      }
      if (e.key === "logoutState") {
        const logoutState = localStorage.getItem("logoutState");
        if (logoutState === LogoutStates.logOut || LogoutStates.endSession) {
          logOut();
        }
      }
    };

    window.addEventListener("storage", handleStorageChange);
    const timeoutInterval = setInterval(checkTimeout, 1000);

    return () => {
      clearInterval(timeoutInterval);
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);

  return (
    <>
      <TokenRefresher />
      {!showTimer && <SessionTimeout />}
      <EndSessionModal modalOpen={showTimer} setOpenModal={setShowTimer} />
    </>
  );
};

export default SessionTimeoutManager;
