import axios from '../../Shared/axios';

export class NavbarService {
  static checkAuthentication() {
    return axios.get('api/authentication');
  }

  static logout() {
    return axios.get('api/authentication/logout');
  }
}
