import styled from "styled-components";

export const H1 = styled.div`
  font-size: 45px;
  line-height: 70px;
  font-weight: 500;
`;

export const H2 = styled.div`
  font-size: 28px;
  line-height: 44px;
  font-weight: 500;
`;

export const H3 = styled.div`
  padding-left: 4px;
  font-size: 18px;
  line-height: 30px;
  font-weight: 500;
  display: inline;
`;

export const H4 = styled.h4`
  font-size: 16px;
  line-height: 28px;
  font-weight: 500;
`;

export const H5 = styled.h5`
  font-size: 16px;
  line-height: 28px;
  font-weight: 500;
  padding: 4px;
`;

export const H6 = styled.h6`
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
`;
