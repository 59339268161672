import React, { createContext, useContext, useState } from "react";
import { FeatureContextProviderModel } from "./Models/FeatureContextProviderModel";

const FeatureContext = createContext<FeatureContextProviderModel | undefined>(
  undefined
);

export const useFeatureContextProvider = () => {
  const userContext = useContext(FeatureContext);
  if (!userContext) {
    throw new Error("Out of context user");
  }
  return userContext;
};

interface FeatureContextProps {
  children: React.ReactNode;
}

export const FeatureContextProvider: React.FC<FeatureContextProps> = (
  props
) => {
  const [state, setState] = useState<{
    outgoingEnabled: boolean;
    incomingEnabled: boolean;
  }>({ outgoingEnabled: false, incomingEnabled: false });

  const [metadataEnabled, setMetadataEnabled] = useState(false);

  return (
    <FeatureContext.Provider
      value={{ state, setState, metadataEnabled, setMetadataEnabled }}
    >
      {props.children}
    </FeatureContext.Provider>
  );
};
