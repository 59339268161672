import { ReactNode } from 'react';

export type LeftSidebarAction =
  | { type: 'hideSidebar' }
  | { type: 'setContent'; content: ReactNode }
  | { type: 'showSidebar'; content: ReactNode };

export interface LeftSidebarState {
  isVisible: boolean;
  content: ReactNode;
}

export const leftSidebarReducer = (state: LeftSidebarState, action: LeftSidebarAction): LeftSidebarState => {
  switch (action.type) {
    case 'hideSidebar':
      return {
        ...state,
        isVisible: false,
      };
    case 'setContent':
      return {
        ...state,
        content: action.content,
      };
    case 'showSidebar':
      return {
        ...state,
        isVisible: true,
        content: action.content,
      };
  }
};
