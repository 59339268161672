import { Col, Collapse as AntCollapse, Row } from 'antd';
import React, { useState } from 'react';
import { Button } from '..';
import DeleteCircle from '../../Shared/Icons/DeleteCircle';
import DownChevron from '../../Shared/Icons/DownChevron';
import UpChevron from '../../Shared/Icons/UpChevron';
import { H3 } from '../../StyledComponents/headingsStyledComponents';
import './Collapse.less';

const { Panel } = AntCollapse;
interface CollapseComposition {
  label: string;
  deleteAction?: () => void;
  children: JSX.Element;
  isReadOnly?: boolean;
  id?: any;
  name?: any;
  hideDeleteButton?: boolean;
}

const Collapse: React.FunctionComponent<CollapseComposition> = (props) => {
  const [isActive, setIsActive] = useState(true);

  const onDelete = (e: any) => {
    e.stopPropagation();
    // @ts-ignore: Unreachable code error
    props.deleteAction();
  };

  const checkKey = (key: any) => {
    key[0] === '1' ? setIsActive(true) : setIsActive(false);
  };

  return (
    <>
      <AntCollapse className='invoice-form' onChange={(key) => checkKey(key)} defaultActiveKey={['1']} ghost>
        <Panel
          showArrow={false}
          header={
            <Row gutter={8} align={'middle'} id={props.id}>
              <Col flex="none">
                {isActive ? (
                  <Button onClick={() => setIsActive(true)} type={'text'} icon={<UpChevron />} />
                ) : (
                  <Button onClick={() => setIsActive(false)} type={'text'} icon={<DownChevron />} />
                )}
              </Col>
              <Col flex="none" className="labelPadding">
                <H3>{props.label}</H3>
              </Col>
              <Col flex="auto">
                <div className="dashedLine"></div>
              </Col>
              {props.deleteAction && !props.hideDeleteButton && !props.isReadOnly && (
                <Col className="collapse-button-delete">
                  <Button
                    onClick={(e: any) => onDelete(e)}
                    type={'text'}
                    icon={<DeleteCircle />}
                    id={`${props.name?.replace(/\./g, '_')}_delete`}
                  />
                </Col>
              )}
            </Row>
          }
          key="1"
        >
          {props.children}
        </Panel>
      </AntCollapse>
    </>
  );
};

export default Collapse;
