import { useLeftSidebarContext } from "./LeftSidebarContext";
import "./LeftSidebar.less";
import { useEffect } from "react";

const LeftSiteBar: React.FC = () => {
  const { state, dispatch } = useLeftSidebarContext();
  useEffect(() => {}, [state.content]);

  return (
    <>
      {state.isVisible && (
        <>
          <div className="left-sidebar">
            <div className="filters-content"> {state.content}</div>
          </div>
          <div
            onClick={() => dispatch({ type: "hideSidebar" })}
            className="sidebar-background"
          ></div>
        </>
      )}
    </>
  );
};
export default LeftSiteBar;
