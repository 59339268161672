export const datePickerRange2006 = (current: any) =>
  current &&
  (current.valueOf() < new Date("2006-01-01") ||
    current.valueOf() > new Date("2050-01-01"));

export const datePickerRange2016 = (current: any) =>
  current &&
  (current.valueOf() < new Date("2016-07-01") ||
    current.valueOf() > new Date("2050-01-01"));

export const dateTimePickerRange = (current: any) =>
  current &&
  (current.valueOf() < new Date("2021-10-01") ||
    current.valueOf() > new Date("9999-12-31"));

export const datePickerRange2022 = (current: any) =>
  current &&
  (current.valueOf() < new Date("2022-01-01") ||
    current.valueOf() > new Date("2050-01-01"));

export const clearEmpties = (o: any) => {
  for (let k in o) {
    if (!o[k]) {
      delete o[k];
    }

    if (!o[k] || typeof o[k] !== "object") {
      continue;
    }
    if (Array.isArray(o[k])) {
      o[k] = o[k].filter((x: any) => !isEmpty(x));
    }
    clearEmpties(o[k]);
    if (typeof o[k] === "object" && Object.keys(o[k]).length === 0) {
      delete o[k];
    }
    if (Array.isArray(o[k])) {
      o[k].filter((o: any) => o != null);
    }
  }
};

const isEmpty = (obj: any) => {
  if (!obj) {
    return true;
  }
  return Object.values(obj).every((x) => !x);
};

export const isNumber = (text: string) => !!+text || +text === 0;

const nonUndefinedArray = (arr1: string[], arr2: string[]): string[] => {
  if (arr1?.length > 0) {
    return arr1;
  }
  if (arr2?.length > 0) {
    return arr2;
  }
  return [];
};

export const getErrorToDisplay = (
  businessErrors: [
    {
      message: string;
      messagePl: string;
    }
  ],
  language: string
): string[] => {
  const plErrors = businessErrors
    .map((error) => error.messagePl)
    .filter((error) => error);

  const enMessage = businessErrors
    .map((error) => error.message)
    .filter((error) => error);

  const errorsInSelectedLanguage = language === "en_GB" ? enMessage : plErrors;

  if (errorsInSelectedLanguage.length > 0) {
    return errorsInSelectedLanguage;
  }
  return nonUndefinedArray(plErrors, enMessage);
};

export const formatTime = (milliseconds: number) => {
  const minutes = Math.floor(milliseconds / 60000);
  const seconds = ((milliseconds % 60000) / 1000).toFixed(0);
  return `${minutes}:${+seconds < 10 ? "0" : ""}${seconds}`;
};