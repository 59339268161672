import { createContext, useContext, useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useLocation, useNavigate } from "react-router";
import { EntitiesService } from "../Entities/Entities.service";
import { useFeatureContextProvider } from "../FeatureContextProvider/FeatureContextProvider";
import {
  DisabledFeatureType,
  GlobalContextService,
} from "../GlobalContextProvider/GlobalContext.services";
import { useGlobalContext } from "../GlobalContextProvider/GlobalContextProvider";
import { AppProfileOptions } from "../GlobalContextProvider/Models/AppProfileOptions";
import { LoginService, useLogin } from "../Login/Login.service";
import { NavbarService } from "../Navbar/Navbar.service";
import { RoleType } from "../Settings/RolesAndPermissions/Models/RoleType";
import { RoleAndPermissionsService } from "../Settings/RolesAndPermissions/RoleAndPermissions.service";
import { CompanyBasicInfo } from "../Settings/Users/Forms/Shared/BasedUserForm/BasicUserFormHooks/useGetCompanies";
import { LoggedUserType } from "./Models/LoggedUserType";
import { LoginData } from "./Models/LoginData";

const UserContext = createContext<
  | {
      user: LoggedUserType | undefined;
      onLoggedIn: (data: LoginData) => void;
      isLoginLoading: boolean;
      useTrySetUserIfNotExist: () => void;
      setUser: React.Dispatch<React.SetStateAction<LoggedUserType>>;
      logout: () => void;
      isUserHaveAssignAnyCompany: () => boolean;
      getUserSelectedCompany: () => string | number;
      roles: RoleType[];
    }
  | undefined
>(undefined);
export const useUserContext = () => {
  const userContext = useContext(UserContext);
  if (!userContext) {
    throw new Error("Out of context user");
  }
  return userContext;
};

interface UserContextProviderProps {
  children: React.ReactNode;
}

const getUserWithDetailsOfAssignedCompanies = (
  loggedUser: LoggedUserType,
  companiesInApplication: CompanyBasicInfo[]
): LoggedUserType => {
  return {
    ...loggedUser,
    detailsOfAssignedCompanies: loggedUser.assignedCompanies.map((id) => {
      const currentCompany = companiesInApplication.find(
        (company) => company.id === id
      );
      return { id, name: currentCompany?.name };
    }),
  };
};

export const UserContextProvider: React.FC<UserContextProviderProps> = (
  props
) => {
  let navigate = useNavigate();
  const location = useLocation();
  const [user, setUser] = useState<LoggedUserType>({} as any);
  const [isLoginLoading, setIsLoginLoading] = useState(false);
  const globalContext = useGlobalContext();

  const goToEnabledPageAfterLogin = (module: DisabledFeatureType) => {
    switch (module) {
      case "":
        navigate("/invoices");
        break;
      case "OUTGOING_INVOICES":
        navigate("/purchases");
        break;
      case "INCOMING_INVOICES":
        navigate("/invoices");
        break;
    }
  };

  const featureContext = useFeatureContextProvider();

  const setAvailableModule = (module: DisabledFeatureType) => {
    switch (module) {
      case "":
        featureContext.setState({
          outgoingEnabled: true,
          incomingEnabled: true,
        });
        break;
      case "INCOMING_INVOICES":
        featureContext.setState({
          outgoingEnabled: true,
          incomingEnabled: false,
        });
        break;
      case "OUTGOING_INVOICES":
        featureContext.setState({
          outgoingEnabled: false,
          incomingEnabled: true,
        });
        break;
    }
  };

  const onUserSuccessAuthorized = (data: LoggedUserType) => {
    setUser(data);
    localStorage.setItem("logoutState", `logIn`);
    GlobalContextService.getDisabledFeatures().then((response) => {
      setAvailableModule(response.data.feature);
      setIsLoginLoading(false);
      if (location.pathname.includes("login")) {
        goToEnabledPageAfterLogin(response.data.feature);
      } else {
        navigate(location.pathname);
      }
    });

    GlobalContextService.getApplicationExtraFeature().then((response) => {
      featureContext.setMetadataEnabled(
        response.data?.features.some(
          (feature) => feature === "INCOMING_INVOICE_ADDITIONAL_METADATA"
        )
      );
    });
  };

  const userLogin = useLogin();
  const onLoggedIn = async (data: LoginData) => {
    setIsLoginLoading(true);
    try {
      const result = await userLogin.mutateAsync(data);
      const companies = await EntitiesService.getAll();
      onUserSuccessAuthorized(
        getUserWithDetailsOfAssignedCompanies(
          result.data,
          companies.data.content
        )
      );
    } catch (error) {
      setIsLoginLoading(false);
    }
  };

  const useTrySetUserIfNotExist = () => {
    useEffect(() => {
      if (!user?.id) {
        tryToAuthenticateUser();
      }
    }, []);
  };

  const tryToAuthenticateUser = async () => {
    const user = await LoginService.checkAuthentication();
    if (!user) {
      return;
    }
    const companies = await EntitiesService.getAll();
    onUserSuccessAuthorized(
      getUserWithDetailsOfAssignedCompanies(user.data, companies.data.content)
    );
  };

  const isUserHaveAssignAnyCompany = (): boolean =>
    user.assignedCompanies.length > 0;

  const getUserSelectedCompany = (): string | number => {
    const companyId = localStorage?.getItem(`${user?.username}`);
    if (companyId && user.assignedCompanies.includes(+companyId)) {
      return +companyId;
    }
    return isUserHaveAssignAnyCompany() ? user.assignedCompanies[0] : "no";
  };

  const logout = () => {
    NavbarService.logout()
      .then(() => {
        sessionStorage.removeItem("importedSelectedPageKey");
      })
      .finally(() => {
        navigate("/login");
        setUser(undefined as any);
      });
  };

  const [appRoles, setAppRoles] = useState<RoleType[]>([]);
  const roleQuery = useQuery(
    "applicationRoles",
    () => RoleAndPermissionsService.getRoles(),
    {
      onSuccess: (query) => {
        setAppRoles(query?.data);
      },
      enabled: user && Object.keys(user)?.length !== 0 && !!!appRoles,
    }
  );

  return (
    <UserContext.Provider
      value={{
        user,
        onLoggedIn: onLoggedIn,
        isLoginLoading,
        useTrySetUserIfNotExist,
        logout,
        setUser,
        isUserHaveAssignAnyCompany,
        getUserSelectedCompany,
        roles: appRoles,
      }}
    >
      {props.children}
    </UserContext.Provider>
  );
};
