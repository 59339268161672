import { Input as AntInput, InputProps as AntInputProps } from "antd";
import { GroupProps as AntGroupProps } from "antd/lib/input";
import React, { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { v4 as uuid } from "uuid";
import { Error } from "../../StyledComponents/warningsStyledComponents";

import * as S from "../../StyledComponents/basicStyledComponents";
import { generateLabel, noValueElement, optional } from "../shared/Utils";
import "./Input.less";
import { TextAreaProps } from "./TextArea/TextArea";
import { FittingText } from "../FittingText/FittingText";

const { TextArea, Group } = AntInput;

export interface Props extends AntInputProps {
  label?: React.ReactNode;
  error?: string;
  isReadOnly?: boolean;
  id?: string;
  inputRef?: any;
  isInTable?: boolean;
  required?: boolean;
  passwordType?: boolean;
  noMargin?: boolean;
}

interface InputComposition {
  TextArea: React.FunctionComponent<TextAreaProps>;
  Group: React.FunctionComponent<AntGroupProps>;
}

const Input: React.FunctionComponent<Props> & InputComposition = (props) => {
  const { t } = useTranslation();
  const {
    label,
    error,
    placeholder,
    inputRef,
    isReadOnly,
    required,
    isInTable,
    noMargin,
    ...restProps
  } = props;
  let id = useMemo(() => uuid(), []);
  if (restProps.id) {
    id = restProps.id;
  }
  const getPlaceholder = () => {
    if (!!placeholder) {
      return placeholder;
    } else if (!props.required) {
      return optional();
    } else {
      return null;
    }
  };
  const checkPlaceholder = getPlaceholder();
  useEffect(() => {
    if (restProps.value === "") {
      // @ts-ignore: Unreachable code error
      restProps.onChange(null as any);
    }
  }, [restProps.value]);
  return (
    <div className="input-content">
      <S.InputWrapper isInTable={!!props.isInTable}>
        {generateLabel(label, !!required, id)}
        {isReadOnly && (
          <S.ReadOnlyValue>
            <FittingText
              title={<>{props.value || noValueElement} </>}
              text={
                <div className="read-only-text">
                  <>{props.value || noValueElement}</>
                </div>
              }
            />
          </S.ReadOnlyValue>
        )}
        <S.InputMargin error={!!error}>
          {!isReadOnly && (
            <>
              {props.passwordType ? (
                <AntInput.Password
                  id={id}
                  {...restProps}
                  placeholder={checkPlaceholder as any}
                  ref={inputRef}
                />
              ) : (
                <AntInput
                  id={id}
                  {...restProps}
                  placeholder={checkPlaceholder as any}
                  ref={inputRef}
                />
              )}
            </>
          )}
        </S.InputMargin>
        <Error>
          {error && (
            <div className="error-input-message" data-testid={`error_${id}`}>
              {t(`error:${error}`, error)}
            </div>
          )}
        </Error>
      </S.InputWrapper>
    </div>
  );
};

Input.TextArea = TextArea;
Input.Group = Group;

export default Input;
