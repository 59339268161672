import React from 'react';
import { Menu as AntMenu, MenuProps as AntMenuProps } from 'antd';

class Menu extends React.Component<AntMenuProps, {}> {
  static Divider = AntMenu.Divider;

  static Item = AntMenu.Item;

  static Submenu = AntMenu.SubMenu;

  static ItemGroup = AntMenu.ItemGroup;

  render() {
    return <AntMenu {...this.props} />;
  }
}

export default Menu;
