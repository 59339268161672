interface SvgPathType {
  path: string;
  transform?: string | undefined;
}

export const SvgPath = {
  ArrowLeft: {
    path: "M28.1715729,15 L17.1507576,3.97918472 C16.7602333,3.58866043 16.7602333,2.95549545 17.1507576,2.56497116 C17.5412819,2.17444687 18.1744469,2.17444687 18.5649712,2.56497116 L32,16 L18.5649712,29.4350288 C18.1744469,29.8255531 17.5412819,29.8255531 17.1507576,29.4350288 C16.7602333,29.0445046 16.7602333,28.4113396 17.1507576,28.0208153 L28.1715729,17 L1,17 C0.44771525,17 6.76353751e-17,16.5522847 0,16 C-6.76353751e-17,15.4477153 0.44771525,15 1,15 L28.1715729,15 Z",
    transform:
      "translate(16.000000, 16.000000) scale(-1, 1) translate(-16.000000, -16.000000)",
  },
  ArrowLeftFill: {
    path: "M24.3431458,14 L16.4436508,6.10050506 C15.6626022,5.31945648 15.6626022,4.05312652 16.4436508,3.27207794 C17.2246994,2.49102936 18.4910294,2.49102936 19.2720779,3.27207794 L32,16 L19.2720779,28.7279221 C18.4910294,29.5089706 17.2246994,29.5089706 16.4436508,28.7279221 C15.6626022,27.9468735 15.6626022,26.6805435 16.4436508,25.8994949 L24.3431458,18 L2,18 C0.8954305,18 1.3527075e-16,17.1045695 0,16 C-1.3527075e-16,14.8954305 0.8954305,14 2,14 L24.3431458,14 Z",
    transform:
      "translate(16.000000, 16.000000) scale(-1, 1) translate(-16.000000, -16.000000) ",
  },
  ArrowRight: {
    path: "M28.1715729,15 L17.1507576,3.97918472 C16.7602333,3.58866043 16.7602333,2.95549545 17.1507576,2.56497116 C17.5412819,2.17444687 18.1744469,2.17444687 18.5649712,2.56497116 L32,16 L18.5649712,29.4350288 C18.1744469,29.8255531 17.5412819,29.8255531 17.1507576,29.4350288 C16.7602333,29.0445046 16.7602333,28.4113396 17.1507576,28.0208153 L28.1715729,17 L1,17 C0.44771525,17 7.17306273e-15,16.5522847 7.10542736e-15,16 C7.03779198e-15,15.4477153 0.44771525,15 1,15 L28.1715729,15 Z",
  },
  Alert: {
    path: "M31.7417725,27.158277 C32.4757619,28.420383 31.5577751,30 30.0877964,30 L1.91220363,30 C0.442224873,30 -0.475761857,28.420383 0.258227533,27.158277 L14.3460239,2.94806782 C15.0820133,1.68397739 16.9179867,1.68397739 17.6539761,2.94806782 L31.7417725,27.158277 Z M16,26 C17.103665,26 18,25.1057591 18,24 C18,22.896335 17.103665,22 16,22 C14.896335,22 14,22.896335 14,24 C14,25.1057591 14.896335,26 16,26 Z M14.0000289,18 L17.9999711,18 L17.9999711,10 L14.0000289,10 L14.0000289,18 Z",
  },
  VerticalMore: {
    path: "M8,12 C6.89533333,12 6,12.8953333 6,14 C6,15.1046667 6.89533333,16 8,16 C9.10466667,16 10,15.1046667 10,14 C10,12.8953333 9.10466667,12 8,12 M8,6 C6.89533333,6 6,6.89533333 6,8 C6,9.10466667 6.89533333,10 8,10 C9.10466667,10 10,9.10466667 10,8 C10,6.89533333 9.10466667,6 8,6 M8,4 C9.10466667,4 10,3.10466667 10,2 C10,0.895333333 9.10466667,0 8,0 C6.89533333,0 6,0.895333333 6,2 C6,3.10466667 6.89533333,4 8,4",
  },
  OpenFolder: {
    path: "M2.00646986,14 L3.61043818,28 L28.3960317,28 L30,14 L2.00646986,14 Z M1.00705163,12 L30.99303,12 C31.5491642,12 32,12.4241513 32,12.9473684 C32,12.980495 31.9981532,13.0135989 31.9944672,13.0465435 L30.2874215,28.3036133 C30.179528,29.2679334 29.3151983,30 28.2845472,30 L3.71553441,30 C2.68488334,30 1.82055366,29.2679334 1.71266017,28.3036133 L0.00561450599,13.0465435 C-0.0526042781,12.5262012 0.348558743,12.0599782 0.901637192,12.0052054 C0.936654437,12.0017375 0.971840915,12 1.00705163,12 Z M28,7 L12,7 L12,3 L4.00646986,3 L4.00646986,10 L2.00646986,10 L2.00646986,3 C2.00646986,1.8954305 2.90190036,1 4.00646986,1 L12,1 C13.1045695,1 14,1.8954305 14,3 L14,5 L28,5 C29.1045695,5 30,5.8954305 30,7 L30,10 L28,10 L28,7 Z",
  },
  Xml: {
    path: "M30,4 C31.104,4 32,4.896 32,6 L32,28 L0,28 L0,4 L30,4 Z M2,26 L30,26 L30,6 L2,6 L2,26 Z M23.5936299,18.4993324 L27.02581,18.4993324 L27.02581,19.8878505 L21.7375069,19.8878505 L21.7375069,12.1121495 L23.5936299,12.1121495 L23.5936299,18.4993324 Z M6.5856123,12.1121495 L7.88160351,14.7343124 L9.1995607,12.1121495 L11.3412411,12.1121495 L9.0512905,15.9679573 L11.5169687,19.8878505 L9.2654585,19.8878505 L7.90356947,17.212283 L6.5416804,19.8878505 L4.4,19.8878505 L6.75035695,15.9679573 L4.46040637,12.1121495 L6.5856123,12.1121495 Z M14.3629666,12.1121495 L16.6305359,17.6351495 L18.8975359,12.1141495 L18.8985359,12.1121495 L20.7559397,12.1121495 L20.7559397,19.8878505 L18.8985359,19.8878505 L18.8975359,16.6361495 L17.5629666,19.8878505 L15.6985359,19.8878505 L14.3555359,16.6181495 L14.3559397,19.8878505 L12.4985359,19.8878505 L12.4985359,12.1121495 L14.3629666,12.1121495 Z",
  },
  DownChevron: {
    path: "M16,26.242245 L31.1210183,11.1212266 C32.2929939,9.95058436 32.2929939,8.05062395 31.1210183,6.87864836 C29.9490427,5.70800608 28.0504156,5.70800608 26.87844,6.87864836 L16,17.7584217 L5.12155997,6.87864836 C4.53490552,6.29332722 3.76825483,6 3.00027083,6 C2.23228683,6 1.46430283,6.29332722 0.878981688,6.87864836 C-0.292993896,8.05062395 -0.292993896,9.95058436 0.878981688,11.1212266 L16,26.242245 Z",
  },
  Pdf: {
    path: "M30,4 C31.104,4 32,4.896 32,6 L32,28 L0,28 L0,4 L30,4 Z M2,26 L30,26 L30,6 L2,6 L2,26 Z M7.86895217,17.2582418 L7.86895217,20 L6,20 L6,12 L9.07437103,12 C10.0291265,12 10.7866528,12.2417558 11.3469726,12.7252747 C11.9072925,13.2087936 12.1874482,13.8443184 12.1874482,14.6318681 C12.1874482,15.4194179 11.9072925,16.0540269 11.3469726,16.5357143 C10.7866528,17.0174017 10.0291265,17.2582418 9.07437103,17.2582418 L7.86895217,17.2582418 Z M7.86895217,15.8296703 L9.07437103,15.8296703 C9.47986563,15.8296703 9.78951142,15.7188656 10.0033177,15.4972527 C10.2171239,15.2756399 10.3240254,14.9908442 10.3240254,14.6428571 C10.3240254,14.287544 10.2180455,13.9963381 10.0060824,13.7692308 C9.7941193,13.5421234 9.48355195,13.4285714 9.07437103,13.4285714 L7.86895217,13.4285714 L7.86895217,15.8296703 Z M13.2214542,20 L13.2214542,12 L16.1686481,12 C17.1160309,12 17.9085767,12.3067735 18.5463091,12.9203297 C19.1840415,13.5338859 19.502903,14.320508 19.502903,15.2802198 L19.502903,16.7197802 C19.502903,17.683155 19.1840415,18.4706929 18.5463091,19.0824176 C17.9085767,19.6941423 17.1160309,20 16.1686481,20 L13.2214542,20 Z M15.0904064,13.4285714 L15.0904064,18.5714286 L16.1686481,18.5714286 C16.5925742,18.5714286 16.9418473,18.3992691 17.2164777,18.0549451 C17.4911082,17.710621 17.6284213,17.2655705 17.6284213,16.7197802 L17.6284213,15.2692308 C17.6284213,14.7307665 17.4911082,14.289379 17.2164777,13.9450549 C16.9418473,13.6007309 16.5925742,13.4285714 16.1686481,13.4285714 L15.0904064,13.4285714 Z M25.4359967,16.7802198 L22.4888029,16.7802198 L22.4888029,20 L20.6198507,20 L20.6198507,12 L26,12 L26,13.4285714 L22.4888029,13.4285714 L22.4888029,15.3516484 L25.4359967,15.3516484 L25.4359967,16.7802198 Z",
  },
  Delete: {
    path: "M24.6352651,27.7119821 L24.9966,10.0745753 L26.9966083,10.0745753 L26.6352735,27.7533156 C26.5872733,30.1133255 24.6592652,32 22.2992554,32 L7.84986187,32 C5.48985204,32 3.56317735,30.1133255 3.51517715,27.7533156 L3.15250897,10.0745753 L5.1525173,10.0745753 L5.51385214,27.7119821 C5.54051892,28.9733207 6.58852329,29.9999917 7.84986187,29.9999917 L22.2992554,29.9999917 C23.5619273,29.9999917 24.6099317,28.9733207 24.6352651,27.7119821 Z M2.00010417,4 L28,4 C28.5533356,4 29.0000042,4.44666853 29.0000042,5.00000417 C29.0000042,5.55333981 28.5533356,6.00000833 28,6.00000833 L2.00010417,6.00000833 C1.44676853,6.00000833 1.0001,5.55333981 1.0001,5.00000417 C1.0001,4.44666853 1.44676853,4 2.00010417,4 Z M20.9999958,2.00000833 L9.00010417,2.00000833 C8.44676853,2.00000833 8.0001,1.55333981 8.0001,1.00000417 C8.0001,0.446668528 8.44676853,0 9.00010417,0 L20.9999958,0 C21.5533315,0 22,0.446668528 22,1.00000417 C22,1.55333981 21.5533315,2.00000833 20.9999958,2.00000833 Z",
  },
  Audience: {
    path: "M20,20.7269539 C21.5063354,20.2635523 23.2003832,20 24.9989791,20 C27.647076,20 30.0685431,20.5713041 32,21.5106012 L32,28 L20,28 L0,28 L0,20.1369854 C2.75620693,18.8086474 6.2145904,18 9.99890627,18 C13.7832221,18 17.2416056,18.8086474 20,20.1369854 L20,20.7269539 Z M18,21.5106012 L18,21.4439124 C15.6461196,20.512879 12.8925112,20 9.99890627,20 C7.10535066,20 4.35199522,20.5128723 2,21.4435824 L2,26 L18,26 L18,21.5106012 Z M10.0007527,14 C7.24296252,14 5,11.7555321 5,8.99924733 C5,6.24296252 7.24296252,4 10.0007527,4 C12.7570375,4 15,6.24296252 15,8.99924733 C15,11.7555321 12.7570375,14 10.0007527,14 Z M10,12 C11.6536697,12 13,10.6548165 13,9 C13,7.34633028 11.6536697,6 10,6 C8.34633028,6 7,7.34633028 7,9 C7,10.6548165 8.34633028,12 10,12 Z M25,18 C22.795107,18 21,16.206422 21,14 C21,11.795107 22.795107,10 25,10 C27.204893,10 29,11.795107 29,14 C29,16.206422 27.204893,18 25,18 Z M25,16 C26.1024465,16 27,15.103211 27,14 C27,12.8975535 26.1024465,12 25,12 C23.8975535,12 23,12.8975535 23,14 C23,15.103211 23.8975535,16 25,16 Z M20,26 L30,26 L30,22.8352004 C28.5095591,22.2960599 26.7959275,22 24.9989791,22 C23.2022015,22 21.4889114,22.2960124 20,22.8348156 L20,26 Z",
  },
  Edit: {
    path: "M28.0664038,30.0263397 L28.0414287,12.3449747 L30,12.3449747 L30,30.0644971 C30,31.134221 29.1337594,32 28.0664038,32 L0,32 L0,3.93418712 C0,2.865779 0.866240595,2 1.93359623,2 L19.6343487,2 L19.6343487,3.97366033 L1.93359623,3.97366033 L1.96908712,30.0263397 L28.0664038,30.0263397 Z M12.6377526,17.9530667 L14.6371861,15.9530667 L16.0514521,17.3677333 L14.0520186,19.3677333 L12.6377526,17.9530667 Z M30.585734,0 L32,1.41466667 L30.407118,3.00666667 L29.4074012,4.00666667 L27.4132996,6.00133333 L26.408251,7.00666667 L17.5041072,15.9146667 L16.0898412,14.5 L24.9966509,5.59066667 L25.9963677,4.59066667 L27.9958012,2.59066667 L28.9955179,1.59066667 L30.585734,0 Z",
  },
  CheckMarkFill: {
    path: "M8,24 L-5.89128746e-12,16 L4,12 L12,20 L28,4 L32,8 L12,28 L8,24 Z",
  },
  Comment: {
    path: "M2.5 20V14.88C1.71333 14.0667 1.10667 13.1467 0.68 12.12C0.226667 11.04 0 9.91333 0 8.74C0 7.15333 0.4 5.68667 1.2 4.34C1.97333 3.02 3.02 1.97333 4.34 1.2C5.7 0.4 7.17333 0 8.76 0H11.26C12.8467 0 14.3133 0.4 15.66 1.2C16.98 1.97333 18.0267 3.02 18.8 4.34C19.6 5.68667 20 7.15333 20 8.74C20 10.3267 19.6 11.8 18.8 13.16C18.0267 14.48 16.98 15.5267 15.66 16.3C14.3133 17.1 12.8467 17.5 11.26 17.5H8.76C8.17333 17.5 7.53333 17.4333 6.84 17.3L2.5 20ZM3.76 17.74L6.6 15.96L7.1 16.06C7.64667 16.18 8.2 16.24 8.76 16.24H11.26C12.62 16.24 13.88 15.9 15.04 15.22C16.1733 14.5533 17.0733 13.66 17.74 12.54C18.42 11.3667 18.76 10.1 18.76 8.74C18.76 7.38 18.42 6.12 17.74 4.96C17.0733 3.82667 16.1733 2.92667 15.04 2.26C13.88 1.58 12.62 1.24 11.26 1.24H8.76C7.4 1.24 6.13333 1.58 4.96 2.26C3.84 2.92667 2.94667 3.82667 2.28 4.96C1.6 6.12 1.26 7.38 1.26 8.74C1.26 9.74 1.44 10.6933 1.8 11.6C2.17333 12.4933 2.70667 13.2933 3.4 14L3.76 14.36V17.74Z",
  },
  Toggle: {
    path: "M25.9997833,16.0012333 C23.0344747,16.0012333 20.5811618,13.8332514 20.1011658,11.001275 L0,11.001275 L0,9.00129166 L20.1011658,9.00129166 C20.5798285,6.16931526 23.0331414,4 25.9997833,4 C29.3077558,4 31.9997333,6.69197757 31.9997333,9.99995 C31.9997333,13.3092558 29.3077558,16.0012333 25.9997833,16.0012333 Z M25.9997833,5.99998333 C23.7944684,5.99998333 21.9998167,7.79463504 21.9998167,9.99995 C21.9998167,12.205265 23.7944684,14.00125 25.9997833,14.00125 C28.2050983,14.00125 29.99975,12.205265 29.99975,9.99995 C29.99975,7.79463504 28.2050983,5.99998333 25.9997833,5.99998333 Z M6.01729974,16 C9.00188041,16 11.4676361,18.184 11.9369855,21.0306667 L32,21.0306667 L32,23.0306667 L11.9302996,23.0306667 C11.436881,25.848 8.98182274,28 6.01729974,28 C2.69976182,28 0,25.3093333 0,22 C0,18.6906667 2.69976182,16 6.01729974,16 Z M5.99996667,25.9999333 C8.20528162,25.9999333 9.99993333,24.2052816 9.99993333,21.9999667 C9.99993333,19.7946517 8.20528162,18 5.99996667,18 C3.79465171,18 2,19.7946517 2,21.9999667 C2,24.2052816 3.79465171,25.9999333 5.99996667,25.9999333 Z",
  },
  HelpQuestion: {
    path: "M16,0 C24.836,0 32,7.164 32,16 C32,24.8373333 24.836,32 16,32 C7.16266667,32 0,24.8373333 0,16 C0,7.164 7.16266667,0 16,0 Z M14.1386667,24 L17.576,24 L17.576,20.6306667 L14.1386667,20.6306667 L14.1386667,24 Z M21.1106667,13.9546667 C21.292,13.5026667 21.384,12.9253333 21.384,12.2253333 C21.384,11.8026667 21.292,11.3466667 21.1106667,10.8573333 C20.928,10.3693333 20.6253333,9.91333333 20.2026667,9.48933333 C19.7786667,9.06666667 19.2213333,8.71333333 18.528,8.428 C17.8346667,8.14266667 16.9706667,8 15.9333333,8 C15.132,8 14.4053333,8.136 13.756,8.40666667 C13.1066667,8.676 12.552,9.05066667 12.0933333,9.53333333 C11.6333333,10.0146667 11.276,10.5826667 11.02,11.24 C10.7653333,11.8973333 10.6306667,12.6186667 10.616,13.408 L13.8333333,13.408 C13.8333333,13.012 13.876,12.6453333 13.9653333,12.3013333 C14.052,11.96 14.1866667,11.66 14.3693333,11.404 C14.552,11.1493333 14.7853333,10.944 15.0693333,10.792 C15.3533333,10.6386667 15.6933333,10.5613333 16.0866667,10.5613333 C16.6706667,10.5613333 17.1266667,10.7213333 17.4546667,11.044 C17.784,11.3653333 17.948,11.86 17.948,12.532 C17.9626667,12.9253333 17.8933333,13.2546667 17.74,13.516 C17.5866667,13.78 17.3853333,14.02 17.1373333,14.24 C16.8893333,14.4573333 16.62,14.676 16.328,14.8946667 C16.036,15.1146667 15.7586667,15.3746667 15.496,15.672 C15.2333333,15.9706667 15.004,16.332 14.8066667,16.756 C14.6106667,17.1786667 14.4893333,17.704 14.4453333,18.3306667 L14.4453333,19.316 L17.4,19.316 L17.4,18.484 C17.4586667,18.048 17.6013333,17.6826667 17.8266667,17.3906667 C18.0533333,17.0986667 18.312,16.84 18.604,16.6133333 C18.896,16.388 19.2066667,16.1613333 19.5346667,15.9346667 C19.8626667,15.7093333 20.1613333,15.4346667 20.432,15.1146667 C20.7013333,14.7933333 20.928,14.4066667 21.1106667,13.9546667 Z",
  },
  HorizontalMore: {
    path: "M4,12 C6.20933333,12 8,13.7906667 8,16 C8,18.2093333 6.20933333,20 4,20 C1.79066667,20 0,18.2093333 0,16 C0,13.7906667 1.79066667,12 4,12 Z M16,12 C18.2093333,12 20,13.7906667 20,16 C20,18.2093333 18.2093333,20 16,20 C13.7906667,20 12,18.2093333 12,16 C12,13.7906667 13.7906667,12 16,12 Z M28,12 C30.2093333,12 32,13.7906667 32,16 C32,18.2093333 30.2093333,20 28,20 C25.7906667,20 24,18.2093333 24,16 C24,13.7906667 25.7906667,12 28,12 Z",
  },
  BoxOpenOutline: {
    path: "M49.85,52.26l.09.12a1.44,1.44,0,0,0-.19.31,1.1,1.1,0,0,0-.21-.36l.05-.07A.76.76,0,0,0,49.85,52.26Zm28.5.28L77.14,51l.06,2.06Zm-57.26,0,.74.33V51.59ZM49.75,79.48a1.19,1.19,0,0,1-1.08.81l1.08.48,1.08-.48a1.15,1.15,0,0,1-.59-.19A1.24,1.24,0,0,1,49.75,79.48Zm-25.47-10,.16.07a1.43,1.43,0,0,1-.16-.24Zm50.94,0v-.16a1.14,1.14,0,0,1-.15.23ZM49.72,52.1l-.13.16a.76.76,0,0,0,.26,0Zm-.22.16s0,.05,0,.07l.05-.07A.27.27,0,0,1,49.5,52.26Zm.35,0,.09.12a.37.37,0,0,1,.07-.12A.85.85,0,0,1,49.85,52.26Zm35.59,1.91-8.13,3.56.31,12.58a1.22,1.22,0,0,1-.71,1.1L50.24,83.18a1.21,1.21,0,0,1-.49.11,1.16,1.16,0,0,1-.48-.11L22.6,71.41a1.21,1.21,0,0,1-.72-1.1l0-12.7L14,54.17,24.72,40.31,14.18,29,37.7,16.71,50,30,62.48,16.71,86,28.76,75,40.64ZM56.14,29.81l15.68,7.86,6.79-7.31L63.08,22.41ZM28.36,37.87,44,30l-6.94-7.4-15.53,8ZM26.57,54.94l11.37,5,5.86-7.67L27,44.86l-.46.61-4.74,6.12-.74.95.74.33Zm20.84.18L39.58,65.38l-13-5.7V68l20.84,9.2ZM68.49,41.47l-18.73-8.9-2.58,1.24L31.45,41.69l18.3,8.08Zm4.45,18.18L59.86,65.38,52.1,55.21v22L72.94,68Zm5.41-7.11L77.14,51l-4.2-5.44-.54-.69-.19-.25-16.7,7.47.15.2,5.84,7.65,11.44-5,4.26-1.87Z",
    viewBox: "0 0 100 100",
  },
  UpChevron: {
    path: "M16,26.242245 L31.1210183,11.1212266 C32.2929939,9.95058436 32.2929939,8.05062395 31.1210183,6.87864836 C29.9490427,5.70800608 28.0504156,5.70800608 26.87844,6.87864836 L16,17.7584217 L5.12155997,6.87864836 C4.53490552,6.29332722 3.76825483,6 3.00027083,6 C2.23228683,6 1.46430283,6.29332722 0.878981688,6.87864836 C-0.292993896,8.05062395 -0.292993896,9.95058436 0.878981688,11.1212266 L16,26.242245 Z",
    transform:
      "translate(16.000000, 16.121122) scale(1, -1) translate(-16.000000, -16.121122) ",
  },
  Transform: {
    path: "M24.3370825,22.2377029 L25.9325069,23.0954102 C23.0655154,27.5237568 17.6254155,29.5362846 12.5666532,28.0400165 C7.50789092,26.5437484 4.03781293,21.8957904 4.04097244,16.6203867 L4.04097244,15.798232 L1.54339988,18.2958046 C1.19119389,18.6492378 0.619160292,18.6502326 0.265727094,18.2980266 C-0.0877061043,17.9458206 -0.0887009454,17.373787 0.263505054,17.0203538 L4.94756461,12.3362943 L9.6671768,17.0692387 C9.8409864,17.2367656 9.9391775,17.4677839 9.9391775,17.7091861 C9.9391775,17.9505884 9.8409864,18.1816067 9.6671768,18.3491335 C9.49800516,18.5193424 9.26719493,18.6139105 9.02722938,18.6113342 C8.78726383,18.6139105 8.5564536,18.5193424 8.38728197,18.3491335 L5.8497127,15.798232 L5.8497127,16.6203867 C5.84951453,21.0662875 8.75738197,24.9891499 13.0112531,26.2816723 C17.2651242,27.5741947 21.863857,25.9321838 24.3370825,22.2377029 Z M7.66289704,10.2920178 L6.06747258,9.43431052 C8.93446415,5.00596393 14.374564,2.99343615 19.4333263,4.48970424 C24.4920886,5.98597234 27.9621666,10.6339303 27.9590071,15.909334 L27.9590071,16.7314887 L30.4565796,14.2339161 C30.684417,14.0052849 31.0169206,13.9155708 31.3288397,13.9985681 C31.6407588,14.0815654 31.8847054,14.324665 31.9687871,14.6362935 C32.0528689,14.947922 31.9643118,15.2807357 31.7364745,15.5093669 L27.0524149,20.1934264 L22.3328027,15.460482 C22.1589931,15.2929551 22.060802,15.0619368 22.060802,14.8205346 C22.060802,14.5791324 22.1589931,14.3481141 22.3328027,14.1805872 C22.5019744,14.0103783 22.7327846,13.9158102 22.9727501,13.9183865 C23.2127157,13.9158102 23.4435259,14.0103783 23.6126976,14.1805872 L26.1502668,16.7314887 L26.1502668,15.909334 C26.150465,11.4634333 23.2425976,7.54057083 18.9887264,6.24804845 C14.7348553,4.95552607 10.1361225,6.5975369 7.66289704,10.2920178 Z",
  },
  Hand: {
    viewBox: "10 20 70 60",
    path: "M23.88,55.32c2.06,3,4.47,6.58,6.6,9.77l1,1.5a50.46,50.46,0,0,1,2.79,4.91c2,3.9,4.15,7.94,8.26,10.61A23.13,23.13,0,0,0,55,86h2.28a21,21,0,0,0,16-7.58C77.5,73.28,77.5,67,77.5,61.53V29.16a6.1,6.1,0,0,0-6.13-5.74H71a5.65,5.65,0,0,0-3.12.93,5.75,5.75,0,0,0-5.73-5.64h-.25a6.26,6.26,0,0,0-3.51,1.1v-.07A6.1,6.1,0,0,0,52.22,14H52a5.73,5.73,0,0,0-5.43,3.88,5.82,5.82,0,0,0-3.43-1.13h-.28a5.74,5.74,0,0,0-5.76,5.74h0v27L35.3,46.69a6.76,6.76,0,0,0-9.47-1.35l0,0-.64.48A6.78,6.78,0,0,0,23.88,55.32ZM73.56,61.5c0,5.09,0,10.36-3.37,14.43a17.13,17.13,0,0,1-13,6.18H55A19.27,19.27,0,0,1,44.63,78.9c-3.26-2.11-5-5.53-6.93-9.16a58.51,58.51,0,0,0-3-5.29l-1-1.5c-2.14-3.21-4.57-6.85-6.69-9.9a2.91,2.91,0,0,1,.45-4.1l.05,0,.64-.48a2.9,2.9,0,0,1,4,.51l4,6.48A2.55,2.55,0,0,0,41,54.05V22.5a1.88,1.88,0,0,1,1.88-1.88h.28A1.88,1.88,0,0,1,45,22.5V48.17a2.75,2.75,0,0,0,2.75,2.75,2.26,2.26,0,0,0,1.66-.67,2.71,2.71,0,0,0,.69-2V19.74a1.88,1.88,0,0,1,1.88-1.88h.3a2.24,2.24,0,0,1,2.27,1.88V48.17a2.75,2.75,0,0,0,2.75,2.75,2.31,2.31,0,0,0,1.7-.67,2.63,2.63,0,0,0,.68-2V24.5a2.25,2.25,0,0,1,2.27-1.88h.29A1.88,1.88,0,0,1,64,24.5V48.17a2.64,2.64,0,0,0,.68,2.08,2.26,2.26,0,0,0,1.67.67,2.75,2.75,0,0,0,2.75-2.75v-19A1.88,1.88,0,0,1,71,27.28h.29a2.25,2.25,0,0,1,2.27,1.88Z",
  },
  Time: {
    path: "M16,0 C24.836,0 32,7.164 32,16 C32,24.836 24.836,32 16,32 C7.16266667,32 0,24.836 0,16 C0,7.164 7.16266667,0 16,0 Z M16,30 C23.72,30 30,23.72 30,16 C30,8.28 23.72,2 16,2 C8.28,2 2,8.28 2,16 C2,23.72 8.28,30 16,30 Z M17.0006667,15.0001333 L25.9206667,15.0001333 L25.9206667,17.0001333 L15.0006667,17.0001333 L15.0006667,6.08013333 L17.0006667,6.08013333 L17.0006667,15.0001333 Z",
  },
  TimeFill: {
    path: "M32,16 C32,24.836 24.836,32 16,32 C7.164,32 0,24.836 0,16 C0,7.164 7.164,0 16,0 C24.836,0 32,7.164 32,16 Z M26,18 L26,13.9731544 L18.0268456,13.9731544 L18.0268456,6 L14,6 L14,18 L26,18 Z",
  },
  Tag: {
    path: "M18.6827519,0 L32,0 L31.9991668,13.3236748 C31.9991336,13.8542777 31.7883136,14.363139 31.4130779,14.7383319 L14.7355272,31.413978 C13.9540753,32.1953407 12.6870915,32.1953407 11.9056396,31.413978 L0.586088925,20.09572 C-0.195362975,19.3143574 -0.195362975,18.0475182 0.586088925,17.2661556 L17.2678498,0.586299691 C17.6431061,0.211086334 18.1520595,0 18.6827519,0 Z M29.9998749,2 L18.6827519,2 L2.00000001,18.68045 L13.3197724,29.9999209 L29.998945,13.3240377 L29.9998749,2 Z M21.1731221,5.17277922 C22.7352192,3.61068205 25.2678791,3.61068205 26.8299763,5.17277922 C28.3920734,6.7348764 28.3920734,9.26753632 26.8299763,10.8296334 C25.2678791,12.3917306 22.7352192,12.3917306 21.1731221,10.8296334 C19.6110248,9.26753632 19.6110248,6.7348764 21.1731221,5.17277922 Z M25.4157628,6.58699281 C24.6347141,5.8059442 23.3683842,5.8059442 22.5873356,6.5869928 C21.806287,7.36804139 21.806287,8.6343713 22.5873357,9.41541993 C23.3683842,10.1964685 24.6347141,10.1964685 25.4157627,9.4154199 C26.1968113,8.63437135 26.1968113,7.36804134 25.4157628,6.58699281 Z",
  },
  Search: {
    path: "M21.169041,19.7417637 L32,30.5727227 L30.5727227,32 L19.7417637,21.169041 C17.652057,22.9352493 14.9503714,24 12,24 C5.372583,24 0,18.627417 0,12 C0,5.372583 5.372583,7.10542736e-15 12,7.10542736e-15 C18.627417,7.10542736e-15 24,5.372583 24,12 C24,14.9503714 22.9352493,17.652057 21.169041,19.7417637 Z M12.0184749,22.0184749 C17.5413224,22.0184749 22.0184749,17.5413224 22.0184749,12.0184749 C22.0184749,6.49562743 17.5413224,2.01847493 12.0184749,2.01847493 C6.49562743,2.01847493 2.01847493,6.49562743 2.01847493,12.0184749 C2.01847493,17.5413224 6.49562743,22.0184749 12.0184749,22.0184749 Z",
  },
  SearchFill: {
    path: "M22.2474682,18.2474682 L32,28 L28,32 L18.2474682,22.2474682 C16.4276754,23.3592966 14.2886599,24 12,24 C5.372583,24 -1.42108547e-14,18.627417 -1.42108547e-14,12 C-1.42108547e-14,5.372583 5.372583,7.10542736e-15 12,7.10542736e-15 C18.627417,7.10542736e-15 24,5.372583 24,12 C24,14.2886599 23.3592966,16.4276754 22.2474682,18.2474682 Z M12,18 C15.3137085,18 18,15.3137085 18,12 C18,8.6862915 15.3137085,6 12,6 C8.6862915,6 6,8.6862915 6,12 C6,15.3137085 8.6862915,18 12,18 Z",
  },
  CircleCheckmark: {
    path: "M16,0 C24.8373333,0 32,7.164 32,16 C32,24.836 24.8373333,32 16,32 C7.164,32 0,24.836 0,16 C0,7.164 7.164,0 16,0 Z M16,30 C23.72,30 30,23.72 30,16 C30,8.28 23.72,2 16,2 C8.28,2 2,8.28 2,16 C2,23.72 8.28,30 16,30 Z M14.0134667,18.5917333 L21.9974667,10.5784 L23.4134667,11.9904 L14.0148,21.4224 L9.4028,16.8090667 L10.8161333,15.3957333 L14.0134667,18.5917333 Z",
  },
  CircleMinusFill: {
    path: "M4.686,4.686 C10.934,-1.562 21.066,-1.562 27.314,4.686 C33.562,10.934 33.562,21.066 27.314,27.314 C21.066,33.562 10.934,33.562 4.686,27.314 C-1.562,21.066 -1.562,10.934 4.686,4.686 Z M8,18 L24,18 L24,14 L8,14 L8,18 Z",
  },
  CircleCheckmarkFill: {
    path: "M16.0001,0.0004 C24.8361,0.0004 32.0001,7.16306667 32.0001,16.0004 C32.0001,24.8364 24.8361,32.0004 16.0001,32.0004 C7.16276667,32.0004 0.0001,24.8364 0.0001,16.0004 C0.0001,7.16306667 7.16276667,0.0004 16.0001,0.0004 Z M14.0174333,22.8377333 L24.8281,11.9884 L21.9947667,9.1644 L14.0121,17.1750667 L10.8161,13.9817333 L7.9881,16.8097333 L14.0174333,22.8377333 Z",
  },
  Info: {
    path: "M16,0 C24.836,0 32,7.164 32,16 C32,24.836 24.836,32 16,32 C7.164,32 0,24.836 0,16 C0,7.164 7.164,0 16,0 Z M16,7 C14.896,7 14,7.89466667 14,9 C14,10.104 14.896,11 16,11 C17.104,11 18,10.104 18,9 C18,7.89466667 17.104,7 16,7 Z M14,25 L18,25 L18,15 L14,15 L14,25 Z",
  },
  InfoOutline: {
    path: "M8 16C6.912 16 5.872 15.792 4.88 15.376C3.93067 14.9707 3.08267 14.3947 2.336 13.648C1.6 12.912 1.02933 12.0693 0.624 11.12C0.208 10.128 0 9.088 0 8C0 6.912 0.208 5.872 0.624 4.88C1.02933 3.93067 1.6 3.088 2.336 2.352C3.08267 1.60533 3.93067 1.02933 4.88 0.624C5.872 0.208 6.912 0 8 0C9.088 0 10.128 0.208 11.12 0.624C12.0693 1.02933 12.912 1.60533 13.648 2.352C14.3947 3.088 14.9707 3.93067 15.376 4.88C15.792 5.872 16 6.912 16 8C16 9.088 15.792 10.128 15.376 11.12C14.9707 12.0693 14.3947 12.912 13.648 13.648C12.912 14.3947 12.0693 14.9707 11.12 15.376C10.128 15.792 9.088 16 8 16ZM8 0.992C6.74133 0.992 5.568 1.312 4.48 1.952C3.424 2.57067 2.58667 3.408 1.968 4.464C1.328 5.552 1.008 6.73067 1.008 8C1.008 9.26933 1.328 10.448 1.968 11.536C2.58667 12.592 3.424 13.4293 4.48 14.048C5.568 14.688 6.74133 15.008 8 15.008C9.26933 15.008 10.448 14.688 11.536 14.048C12.592 13.4293 13.4293 12.592 14.048 11.536C14.688 10.448 15.008 9.26933 15.008 8C15.008 6.73067 14.688 5.552 14.048 4.464C13.4293 3.408 12.592 2.57067 11.536 1.952C10.448 1.312 9.26933 0.992 8 0.992ZM7.504 12V6.992H8.496V12H7.504ZM8 4.992C7.86133 4.992 7.744 4.944 7.648 4.848C7.552 4.752 7.504 4.63467 7.504 4.496C7.504 4.35733 7.552 4.24 7.648 4.144C7.744 4.048 7.86133 4 8 4C8.13867 4 8.256 4.048 8.352 4.144C8.448 4.24 8.496 4.35733 8.496 4.496C8.496 4.63467 8.448 4.752 8.352 4.848C8.256 4.944 8.13867 4.992 8 4.992Z",
  },
  ConfirmedFill: {
    path: "M15.5146667,22.048 L8.064,14.5946667 L10.892,11.7666667 L15.5106667,16.3853333 L27.2333333,4.62666667 C27.204,4.59733333 27.1786667,4.56533333 27.1493333,4.536 C24.2666667,1.73333333 20.3386667,0 16,0 C7.16266667,0 0,7.16266667 0,16 C0,24.836 7.16266667,32 16,32 C24.836,32 32,24.836 32,16 C32,12.9973333 31.156,10.2 29.7146667,7.80266667 L15.5146667,22.048 Z",
  },
  EmailFill: {
    path: "M31.9992,7 L15.9996,14 L0,7 L0,5.99995 C0,4.8959776 0.895977601,4 1.99995,4 L29.99925,4 C31.1032224,4 31.9992,4.8959776 31.9992,5.99995 L31.9992,7 Z M16.0004,18 L32,11 L32,25.99945 C32,27.1054224 31.1040224,27.9994 30.00005,27.9994 L2.00074998,27.9994 C0.894777631,27.9994 0.000799980001,27.1054224 0.000799980001,25.99945 L0.000799980001,11 L16.0004,18 Z",
  },
  Email: {
    path: "M30,4.0003 C31.104,4.0003 32,4.89496667 32,6.0003 L32,26.0003 C32,27.1043 31.104,28.0003 30,28.0003 L2,28.0003 C0.896,28.0003 0,27.1043 0,26.0003 L0,6.0003 C0,4.89496667 0.896,4.0003 2,4.0003 L30,4.0003 Z M30,26.0003 L30,10.7069667 L16,17.4336333 L2,10.7069667 L2,26.0003 L30,26.0003 Z M30,8.4883 L30,6.0003 L2,6.0003 L2,8.48696667 L16,15.2149667 L30,8.4883 Z",
  },
  FolderOpenedFill: {
    path: "M14,6 L28,6 C29.1045695,6 30,6.8954305 30,8 L30,10 L2,10 L2,6 L2,4 C2,2.896 2.896,2 4,2 L12,2 C13.104,2 14,2.896 14,4 L14,6 Z M1.00746922,12 L30.9926196,12 C31.5489804,12 32,12.4477153 32,13 C32,13.0368952 31.9979431,13.073762 31.9938386,13.1104315 L30.3627053,27.6830796 C30.269739,28.513646 30.157337,28.8068657 29.970706,29.0998899 C29.784075,29.3929142 29.5308041,29.6179417 29.2168127,29.7697131 C28.9028213,29.9214846 28.5968315,30 27.7549862,30 L4.24510261,30 C3.40325734,30 3.09726755,29.9214846 2.78327616,29.7697131 C2.46928478,29.6179417 2.21601386,29.3929142 2.02938285,29.0998899 C1.84275183,28.8068657 1.73034981,28.513646 1.63738355,27.6830796 L0.00625024916,13.1104315 C-0.0551895253,12.5615247 0.343264699,12.0671059 0.896222669,12.0061163 C0.933162797,12.0020419 0.970301769,12 1.00746922,12 Z",
  },
  PaperAirplaneFill: {
    path: "M86,25.5s-6.68,16.71-10.28,25S68.54,67.15,66,74.19l-.11.31-.28-.18L49.5,63.81,43.22,59.7l-.32-.2.3-.23c3.36-2.48,11.5-9.21,19.29-15.65l8.89-7.23.67-.51,0,0,6.76-5.51L59.91,39.14c-8.63,4.45-17.56,9-26.32,13.58a2.6,2.6,0,0,1-1.24.36,2.47,2.47,0,0,1-1.21-.35c-3.77-2-7.61-4.07-11.33-6l-4.43-2.34c-.14-.08-.27-.17-.46-.3l-.4-.28L14,43.42l.61-.13c10.07-2.09,23.08-5.46,35.67-8.72C62.7,31.35,86,25.5,86,25.5Zm-14,10.38,0,0-.65.53Zm6.74-5.53c-1.43,1.08-3.86,3-6.8,5.44l0,.07Zm-9.14,7.36-7.16,5.91,8.89-7.23Zm-3.34.67c-9.12,4.85-27.1,14.39-31.2,16.53a1.78,1.78,0,0,0-.82.94c.58,3.68,1.24,7.34,1.93,11.21l.33,1.87c.24-.78.47-1.55.71-2.32l.08-.27c.63-2.09,1.28-4.25,2-6.35A5.16,5.16,0,0,1,41,57.68C44.6,55,58.75,44.16,66.31,38.38ZM72,35.86l0-.07-2.34,1.92,1.73-1.32ZM40.2,70.55,46.91,66,42.43,63.1Z",
  },
  Share: {
    path: "M1.328 0H16V14.672C16 15.0347 15.872 15.344 15.616 15.6C15.36 15.8667 15.0453 16 14.672 16H1.328C0.965333 16 0.650667 15.8667 0.384 15.6C0.128 15.344 0 15.0347 0 14.672V1.328C0 0.954666 0.128 0.64 0.384 0.384C0.650667 0.128 0.965333 0 1.328 0ZM15.008 15.008V0.992H1.008V15.008H15.008ZM6.592 10.112L5.888 9.408L11.792 3.504H8C7.86133 3.504 7.744 3.456 7.648 3.36C7.552 3.25333 7.504 3.13067 7.504 2.992C7.504 2.85333 7.552 2.736 7.648 2.64C7.744 2.544 7.86133 2.496 8 2.496H13.504V8C13.504 8.13867 13.456 8.256 13.36 8.352C13.264 8.448 13.1467 8.496 13.008 8.496C12.8693 8.496 12.7467 8.448 12.64 8.352C12.544 8.256 12.496 8.13867 12.496 8V4.208L6.592 10.112Z",
  },
  Close: {
    path: "M8 6.176L14.176 0L16 1.824L9.824 8L16 14.176L14.176 16L8 9.824L1.824 16L0 14.176L6.176 8L0 1.824L1.824 0L8 6.176Z",
  },
  Revert: {
    path: "M25.1353 31.3055H9.8464L22.802 18.3389C34.7353 6.39441 54.1464 6.39441 66.0797 18.3389C78.0131 30.2833 78.0131 49.7277 66.0797 61.6722C54.1464 73.6166 34.7353 73.6166 22.802 61.6722L18.9242 65.55C32.9908 79.6389 55.8797 79.6389 69.9464 65.55C84.0131 51.4611 84.0131 28.5389 69.9464 14.45C55.8797 0.361078 32.9908 0.361078 18.9242 14.45L5.97973 27.4277V12.1277C5.97973 10.6166 4.75751 9.3833 3.23529 9.3833C1.72418 9.3833 0.501953 10.6055 0.501953 12.1277V36.7944H25.1353C26.6464 36.7944 27.8686 35.5611 27.8686 34.05C27.8686 33.2944 27.5575 32.6055 27.0686 32.1055C26.5686 31.6055 25.8908 31.3055 25.1353 31.3055ZM44.6464 25.2611L44.5353 41.9722L59.1242 50.3055L56.6464 54.65L39.5131 44.8611L39.6464 25.2166L44.6464 25.25V25.2611Z",
  },
  AccountConvert: {
    path: "M8.00104 8.57336C9.47882 8.57336 10.7522 9.10892 11.3455 9.88225C11.6522 10.2823 11.361 10.86 10.8566 10.86H5.14549C4.64104 10.86 4.34993 10.2823 4.6566 9.88225C5.24993 9.10892 6.52549 8.57336 8.00104 8.57336ZM8.00104 4.68225C7.22327 4.68225 6.59215 5.31114 6.59215 6.08669C6.59215 6.86225 7.22327 7.49114 8.00104 7.49114C8.77882 7.49114 9.40993 6.86225 9.40993 6.08669C9.40993 5.31114 8.77882 4.68225 8.00104 4.68225ZM12.1699 10.9734C11.2633 12.32 9.72104 13.2067 7.97438 13.2067C5.18993 13.2067 2.92549 10.9489 2.92549 8.17336V7.76225L4.19438 9.03336C4.28327 9.12225 4.39882 9.16447 4.51438 9.16447C4.62993 9.16447 4.74549 9.12003 4.83438 9.03336C5.00993 8.85781 5.00993 8.57336 4.83438 8.39558L2.47438 6.03558L0.132155 8.37336C-0.0434006 8.54892 -0.0434006 8.83336 0.132155 9.01114C0.307711 9.1867 0.594377 9.1867 0.772155 9.01114L2.02104 7.76447V8.17558C2.02104 11.4467 4.69215 14.1111 7.97438 14.1111C10.0633 14.1111 11.9033 13.0311 12.9655 11.4045L12.1677 10.9756L12.1699 10.9734ZM3.83215 5.02225C4.73882 3.67558 6.28104 2.78892 8.02771 2.78892C10.8122 2.78892 13.0766 5.04669 13.0766 7.82225V8.23336L11.8077 6.96225C11.7188 6.87336 11.6033 6.83114 11.4877 6.83114C11.3722 6.83114 11.2566 6.87558 11.1677 6.96225C10.9922 7.13781 10.9922 7.42225 11.1677 7.60003L13.5277 9.96003L15.8677 7.62669C16.0433 7.45114 16.0433 7.16669 15.8677 6.98892C15.6922 6.81336 15.4055 6.81336 15.2277 6.98892L13.9788 8.23558V7.82447C13.9788 4.55336 11.3077 1.88892 8.02549 1.88892C5.9366 1.88892 4.0966 2.96892 3.03438 4.59558L3.83215 5.02447V5.02225Z",
  },
  Lock: {
    path: "M5.99981 0C6.53581 0 7.02781 0.132 7.47581 0.396C7.93181 0.66 8.29181 1.02 8.55581 1.476C8.81981 1.924 8.95181 2.416 8.95181 2.952V4.524H10.9918V11.232C10.9758 11.464 10.8998 11.648 10.7638 11.784C10.6118 11.928 10.4278 12 10.2118 12H1.78781C1.56381 12 1.37581 11.924 1.22381 11.772C1.07981 11.628 1.00781 11.448 1.00781 11.232V4.524H3.04781V2.952C3.04781 2.416 3.17981 1.924 3.44381 1.476C3.70781 1.02 4.06381 0.66 4.51181 0.396C4.96781 0.132 5.46381 0 5.99981 0ZM10.2118 5.292H1.78781V11.232H10.2118V5.292ZM6.49181 6.996V9.612H5.50781V6.996H6.49181ZM5.99981 0.948C5.63181 0.948 5.29181 1.04 4.97981 1.224C4.66781 1.4 4.41581 1.64 4.22381 1.944C4.03981 2.248 3.93981 2.584 3.92381 2.952V4.524H8.07581V2.952C8.06781 2.584 7.96781 2.248 7.77581 1.944C7.58381 1.64 7.33181 1.4 7.01981 1.224C6.70781 1.04 6.36781 0.948 5.99981 0.948Z",
  },
  Document: {
    viewBox: "0 0 37 36",
    g: (
      <g id="Vector">
        <path d="M18.5024 21H9.50244V23.25H18.5024V21Z" />,
        <path d="M27.5024 14.25H9.50244V16.5H27.5024V14.25Z" />,
        <path d="M27.5024 7.5H9.50244V9.75H27.5024V7.5Z" />,
        <path d="M30.7824 0H6.22244C4.92744 0 3.87744 1.075 3.87744 2.4V33.6C3.87744 34.925 4.92744 36 6.22244 36H30.7774C32.0724 36 33.1224 34.925 33.1224 33.6V2.4C33.1224 1.075 32.0724 0 30.7774 0H30.7824ZM30.8774 33.6C30.8774 33.695 30.8124 33.75 30.7824 33.75H6.22244C6.18744 33.75 6.12744 33.7 6.12744 33.6V2.4C6.12744 2.3 6.19244 2.25 6.22244 2.25H30.7774C30.8124 2.25 30.8724 2.3 30.8724 2.4V33.6H30.8774Z" />
      </g>
    ),
  },
  Documents: {
    viewBox: "0 0 37 36",
    g: (
      <g id="Vector">
        <path d="M22 21.235H13V23.51H22V21.235Z" />
        <path d="M13 14.41V16.685H28.5V14.41H13Z" />
        <path d="M13 7.585V9.86H28.5V7.585H13Z" />
        <path d="M31.78 0H9.72C8.425 0 7.375 1.09 7.375 2.43V4.15H5.22C3.925 4.15 2.875 5.24 2.875 6.585V33.57C2.875 34.915 3.925 36 5.22 36H27.28C28.575 36 29.625 34.91 29.625 33.57V31.35H31.78C33.075 31.35 34.125 30.265 34.125 28.925V2.43C34.125 1.09 33.075 0 31.78 0ZM27.375 33.57C27.375 33.67 27.31 33.72 27.28 33.72H5.22C5.185 33.72 5.125 33.67 5.125 33.57V6.58C5.125 6.48 5.19 6.425 5.22 6.425H7.375V28.92C7.375 30.26 8.425 31.345 9.72 31.345H27.375V33.57ZM31.875 28.925C31.875 29.025 31.81 29.075 31.78 29.075H9.72C9.685 29.075 9.625 29.025 9.625 28.925V2.43C9.625 2.33 9.69 2.28 9.72 2.28H31.78C31.815 2.28 31.875 2.33 31.875 2.43V28.925Z" />
      </g>
    ),
  },
  Workflow: {
    viewBox: "0 0 100 100",
    g: (
      <g id="Outline">
        <path d="M39.5,18.48h-21v21h21ZM41.75,14A2.25,2.25,0,0,1,44,16.23V41.74A2.25,2.25,0,0,1,41.75,44H16.26A2.25,2.25,0,0,1,14,41.74V16.23A2.25,2.25,0,0,1,16.26,14Z" />
        <path d="M27.63,52v26l-5.95-6A2.06,2.06,0,1,0,18.76,75L29.7,86,40.64,75a2.06,2.06,0,1,0-2.92-2.92l-5.95,6V52" />
        <path d="M60.51,81.48h21v-21h-21ZM58.26,86A2.25,2.25,0,0,1,56,83.73V58.22A2.25,2.25,0,0,1,58.26,56h25.5A2.25,2.25,0,0,1,86,58.22V83.73A2.25,2.25,0,0,1,83.76,86Z" />
        <path d="M72.38,47.93v-26l6,6a2.06,2.06,0,0,0,2.92-2.92L70.32,14l-10.94,11a2.06,2.06,0,0,0,2.92,2.92l6-6v26" />
      </g>
    ),
  },
};
