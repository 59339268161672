import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import Button from '../../Button/Button';
import Menu from '../../Menu/Menu';
import Popconfirm from '../../Popconfirm/Popconfirm';
import Dropdown from '../Dropdown';
import { MenuItem, MenuItemType } from './DropdownMenu.types';

interface Props {
  menuItems: MenuItem[];
  triggerElement?: React.ReactNode;
  trigger?: ('click' | 'hover' | 'contextMenu')[];
}

const DropdownMenu: React.FunctionComponent<Props> = ({ menuItems, trigger = ['click'], triggerElement }) => {
  const [t] = useTranslation();
  const [visible, setVisible] = useState<boolean>(false);

  const handleMenuClick = (e: any) => (e.key === undefined ? setVisible(true) : setVisible(false));

  const confirm = (action: () => void | undefined) => {
    setVisible(false);
    action();
  };

  const handleVisibleChange = (flag: boolean) => {
    setVisible(flag);
  };

  const renderOverlay = () => (
    <Menu onClick={handleMenuClick}>
      {menuItems
        .filter((menuItem) => !menuItem.hidden)
        .map((menuItem) => {
          switch (menuItem.type) {
            case MenuItemType.ACTION:
              return (
                <Menu.Item key={menuItem.key} onClick={menuItem.action} icon={menuItem.icon}>
                  {menuItem.label}
                </Menu.Item>
              );
            case MenuItemType.CONFIRM_ACTION:
              return (
                <Popconfirm
                  placement="left"
                  title={menuItem.confirmTitle}
                  onConfirm={() => confirm(menuItem.onConfirm as any)}
                  okText={menuItem.okText || t('yes', 'Yes')}
                  cancelText={menuItem.okText || t('no', 'No')}
                >
                  <Menu.Item key={menuItem.key} icon={menuItem.icon}>
                    {menuItem.label}
                  </Menu.Item>
                </Popconfirm>
              );
            case MenuItemType.DIVIDER:
              return <Menu.Divider key={menuItem.key} />;
            default:
              return null;
          }
        })}
    </Menu>
  );

  const renderHamburger = () => <Button icon={<FontAwesomeIcon icon="hamburger" />} />;

  return (
    <Dropdown overlay={renderOverlay()} trigger={trigger} onOpenChange={handleVisibleChange} open={visible}>
      {triggerElement || renderHamburger()}
    </Dropdown>
  );
};

export default DropdownMenu;
