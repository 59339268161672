import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { CountdownCircleTimer } from "react-countdown-circle-timer";
import { useTranslation } from "react-i18next";
import { usePageVisibility } from "react-page-visibility";
import { useNavigate } from "react-router";
import { Button } from "../../../../../../DesignSystem";
import { Colors } from "../../../../../../Shared/Colors";
import { formatTime } from "../../../../../../Shared/utils";
import { tKeys } from "../../../../../Constant/tKeys";
import { NavbarService } from "../../../../../Navbar/Navbar.service";
import { LogoutStates, idleTimeSec } from "../../SessionTimerManager";
import {
  EndSessionContentBox,
  EndSessionDesc,
  EndSessionTextBox,
  EndSessionTitle,
  EndSessionsButtonBox,
  RemainingTime,
} from "./EndSessionTimerStyled";
interface EndSessionTimerProps {
  setOpenModal: Dispatch<SetStateAction<boolean>>;
}

export const EndSessionTimer: React.FC<EndSessionTimerProps> = (props) => {
  const { t } = useTranslation();
  let navigate = useNavigate();

  const logOut = (logoutState: LogoutStates) => {
    NavbarService.logout()
      .then(() => {
        localStorage.setItem("logoutState", logoutState);
        navigate("/login");
      })
      .catch(() => {
        localStorage.setItem("logoutState", logoutState);
        navigate("/login");
      });
  };

  const logOutEndSessionInfo = () => {
    logOut(LogoutStates.endSession);
  };

  const logOutOnRequest = () => {
    logOut(LogoutStates.logOut);
  };

  const durationTime = 120;
  const showClock = usePageVisibility();
  const [seconds, setSeconds] = useState(durationTime);

  const incrementTime = () => {
    setSeconds(seconds - 1);
    if (seconds <= 0 && !temp) {
      logOutEndSessionInfo();
    }
  };

  useEffect(() => {
    const timer = setInterval(incrementTime, 1000);
    return () => {
      clearInterval(timer);
    };
  }, [seconds]);

  const [temp, setTemp] = useState(false);
  useEffect(() => {
    const lastActivity = localStorage.getItem("lastActivity");
    if (lastActivity) {
      const currentTime = new Date().getTime();
      const elapsedSec = (currentTime - Number(lastActivity)) / 1000;
      setSeconds(durationTime - elapsedSec + idleTimeSec);
      setTemp(showClock);
    }
  }, [showClock]);

  return (
    <>
      {temp && (
        <>
          <EndSessionContentBox>
            <CountdownCircleTimer
              isPlaying
              duration={durationTime}
              strokeWidth={10}
              initialRemainingTime={seconds}
              colors={[Colors.Orange, Colors.Orange]}
              trailColor={Colors.Darker}
              colorsTime={[180, 0]}
              onComplete={logOutEndSessionInfo}
              rotation={"counterclockwise"}
            >
              {({ remainingTime }) => (
                <RemainingTime>
                  {formatTime(remainingTime * 1000)}
                </RemainingTime>
              )}
            </CountdownCircleTimer>
            <EndSessionTextBox>
              <EndSessionTitle>{t(tKeys.endSessions.title)}</EndSessionTitle>
              <EndSessionDesc>
                <p
                  dangerouslySetInnerHTML={{
                    __html: t(tKeys.endSessions.desc),
                  }}
                />
              </EndSessionDesc>
            </EndSessionTextBox>
          </EndSessionContentBox>
          <EndSessionsButtonBox>
            <Button
              type="primary"
              onClick={() => {
                props.setOpenModal(false);
              }}
            >
              {t(tKeys.endSessions.button.keepWorking)}
            </Button>
            <Button type="text" onClick={logOutOnRequest}>
              {t(tKeys.endSessions.button.logOutNow)}
            </Button>
          </EndSessionsButtonBox>
        </>
      )}
    </>
  );
};
