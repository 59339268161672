import styled, { css } from "styled-components";
import { Colors } from "../Shared/Colors";

export const Title = styled.h2`
  padding: 15px 10px 0px 10px;
  font-weight: bold;
`;

export const TitleBar = styled.div`
  padding: 25px 0 25px 0;
`;

export const SmallTitle = styled.h4`
  padding: 5px;
`;

export const SubSectionTitle = styled.h3`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 4px;
  font-weight: bold;
  text-transform: uppercase;
`;

export const FormWrapper = styled.div`
  & .form-item {
    margin-bottom: 16px;
    font-size: 14px;
  }
`;

export const Page = styled.div`
  padding: 70px 0px 0px 0px;
  margin: auto;
  width: 1400px;
  @media (max-width: 1399px) {
    width: 1366px;
    padding: 70px 10px;
  }
  @media (max-width: 1366px) {
    width: 100%;
    min-width: 1024px;
    padding: 70px 10px;
  }
`;

export const Content = styled.div`
  padding: 5px 10px;
  position: absolute;
  z-index: 5;
  width: 100%;
`;

export const WellFitContent = styled.div`
  padding: 10px 30px 30px 30px;
`;

export const ContentClear = styled.div`
  padding: 5px 10px;
  background: #fff;
`;

export const Row = styled.div`
  padding-bottom: 15px;
`;

export const SingleLine = styled.div`
  padding: 8px 15px 15px 4px;
`;

export const Ghost = styled.div`
  .ant-btn-text {
    color: #ffffff;
  }
`;

export const SubtleText = styled.div`
  color: #7d7d7d;
  font-weight: 300;
`;

export const Currency = styled.div`
  text-align: right;
`;

export const NoValue = styled.span`
  color: #bbb;
`;

export const RowAndCol = styled.div`
  .ant-col {
    padding: 2px;
  }
  .ant-row {
    padding: 10px;
  }
`;

export const ReadOnlyValue = styled.div`
  min-height: 28px;
  box-sizing: border-box;
  padding-left: 5px;
  border: 1px solid #dedede;
  border-radius: 2px;
  background-color: #f2f2f2;
  color: #bdbdbd;
`;

export const ReadOnlyValueConditionaly = styled.div(
  (props: { readOnly: boolean }) => css`
    ${props.readOnly &&
    css`
      min-height: 28px;
      box-sizing: border-box;
      padding-left: 5px;
      border: 1px solid #dedede;
      border-radius: 2px;
      background-color: #f2f2f2;
      color: #bdbdbd;
    `}
  `
);

export const ValidationBorder = styled.div(
  (props: { error: boolean }) => css`
    ${props.error &&
    css`
      width: 100%;
      border: red 0.5px solid;
      border-radius: 5px;
      padding: 10px;
    `}
  `
);

export const BigTableWrapper = styled.div`
  .ant-table-cell {
    min-width: 150px;
  }
`;

export const HeaderWithActionButton = styled.div`
  margin: 30px 0px 25px 0px;
  display: flex;
  justify-content: space-between;
`;

export const LanguageSwitcher = styled.div`
  .ant-btn-text {
    color: rgba(0, 0, 0, 0.65);
    font-weight: 400;
    &:hover,
    &:active,
    &:focus,
    &:focus-visible {
      color: rgba(0, 0, 0, 0.65);
      font-weight: 400;
    }
  }
`;

export const ContentSpace = styled.div`
  padding-bottom: 20px;
  padding-top: 20px;
`;

export const Clickable = styled.div`
  cursor: pointer;
  display: inline;
`;

export const InputWrapper = styled.div(
  (props: { isInTable?: boolean }) => css`
    min-height: ${props.isInTable ? "0px" : "60px"};
  `
);

export const TextFs12Lh24 = styled.div`
  font-size: 16px;
  letter-spacing: 0;
  line-height: 24px;
`;

export const InputMargin = styled.div(
  (props: { isInTable?: boolean; noMargin?: boolean; error: boolean }) => css`
    ${!props.noMargin &&
    css`
      margin: 0px 0px 0px 0px;
    `}
    .ant-input-affix-wrapper-focused {
      border-color: ${Colors.Yellow} !important;
      box-shadow: 0px 0px 0px 1px #ffb600;
      input {
        border-color: ${Colors.Yellow} !important;
      }
    }
    ${props.error &&
    css`
      .ant-select-selector {
        border-color: ${Colors.Error} !important;
        border-width: 2px !important;
      }
      .ant-select-selector:focus {
        border-color: ${Colors.Error} !important;
        border-width: 1px !important;
      }
      .ant-select-focused {
        .ant-select-selector {
          border-color: ${Colors.Yellow} !important;
          border-width: 1px !important;
        }
      }
      .ant-select:hover {
        .ant-select-selector {
          border-color: ${Colors.Yellow} !important;
          border-width: 1px !important;
        }
      }
      .ant-picker {
        border-color: ${Colors.Error} !important;
        border-width: 2px !important;
      }

      .ant-picker:focus {
        border-color: ${Colors.Yellow} !important;
        border-width: 1px !important;
      }

      .ant-picker:hover {
        border-color: ${Colors.Yellow} !important;
        border-width: 1px !important;
      }

      .ant-picker-focused {
        border-color: ${Colors.Yellow} !important;
      }
      .ant-picker:hover {
        border-color: ${Colors.Yellow} !important;
      }
      input {
        border-color: ${Colors.Error};
        border-width: 2px !important;
      }
      input:hover {
        border-width: 1px !important;
      }
      input:focus {
        border-width: 1px !important;
      }
      .ant-input-affix-wrapper {
        border-color: ${Colors.Error} !important;
      }
      .ant-input-affix-wrapper-focused {
        border-color: ${Colors.Yellow} !important;
      }

      .ant-input-affix-wrapper-focused:hover {
        border-color: ${Colors.Yellow} !important;
      }

      .ant-input-affix-wrapper:hover {
        border-color: ${Colors.Yellow} !important;
      }
     
    `}
  `
);

export const FilterTitle = styled.div`
  color: #2d2d2d;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 20px;
  text-transform: uppercase;
  padding: 0px 10px;
`;

export const AllCenter = styled.div`
  display: flex;
  align-items: center;
  align-content: stretch;
  justify-content: center;
`;

export const VerticalCenter = styled.div`
  display: flex;
  align-items: center;
`;

export const Filter = styled.div`
  background-color: white;
  position: absolute;
  z-index: 4;
  width: 400px;
`;

export const BreakWords = styled.div`
  word-wrap: break-word;
  word-break: break-word;
`;

export const MainTabTitle = styled.div`
  margin-top: 20px;
  margin-top: 24px;
`;

export const FontBold = styled.span`
  font-weight: 700;
`;

export const RenderEmpty = styled.div`
  text-align: center;
`;

export const UppercaseText = styled.div`
  text-transform: uppercase;
`;

export const IconSvg = styled.div(
  (props: { color: string }) => css`
    display: inline;
    color: ${props.color};
  `
);

export const LeftSiteBarTitle = styled.div`
  display: flex;
  justify-content: space-between;
  color: #2d2d2d;
  font-size: 22px;
  align-items: baseline;
  font-weight: bold;
  border-bottom: solid 1px #dedede;
  padding-bottom: 10px;
  margin-bottom: 10px;
`;

export const ModalLine = styled.div(
  (props: { noInline?: boolean }) => css`
    margin-bottom: 19px;
    ${!props.noInline &&
    css`
      display: flex;
    `}
  `
);

export const ModalValue = styled.div(
  (props: { noMargin?: boolean }) => css`
    font-weight: bold;
    ${!props.noMargin &&
    css`
      margin-left: 5px;
    `}
  `
);

export const Scrollable = styled.div`
  overflow: auto;
  min-height: 30px;
`;

export const LinkListNavContent = styled.div(
  (props: { height: number }) => css`
    height: ${props.height}px;
    display: flex;
    flex-direction: column;
  `
);

export const LinkListMainNavContent = styled.div(
  (props: { height: number }) => css`
    height: ${props.height}px;
    background-color: #ffffff;
    box-shadow: 0 3px 7px 1px rgba(0, 0, 0, 0.16);
    border-radius: 2px;
    background: white;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-top: -18px;
    padding-left: 8px;
    padding-top: 4px;
    transition: height 0.4s;
    padding-right: 10px;
  `
);

export const TextColor = styled.div(
  (props: { color: Colors }) => css`
    color: ${props.color};
  `
);

export const Inline = styled.div`
  display: flex;
`;

export const SubTabs = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  .ant-tabs {
    width: 960px;
  }
  h6 {
    color: ${Colors.Gray};
  }
  svg {
    fill: ${Colors.Gray};
  }

  .ant-tabs-tab-active {
    svg {
      fill: ${Colors.Orange};
    }
    .ant-tabs-tab-btn {
      h6 {
        color: ${Colors.Orange};
      }
    }
  }
`;

export const Line = styled.div(
  (props: {
    color?: Colors | undefined;
    height?: number | undefined;
    marginTop?: number;
    marginBottom?: number;
  }) => css`
    width: 100%;
    background-color: ${props.color ?? Colors.Lightest};
    height: ${props.height ? `${props.height}px` : "1px"};
    margin-top: ${props.marginTop ? `${props.marginTop}px` : "0px"};
    margin-bottom: ${props.marginBottom ? `${props.marginBottom}px` : "0px"};
  `
);
