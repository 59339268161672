import {
  CompanyDropdownSettingsMode,
  CompanyDropdownSettingsType,
  NavbarState,
} from "./NavbarContextProviderState";

type NavbarContextAction =
  | {
      type: "setCompanyDropdownSettings";
      settings: CompanyDropdownSettingsType;
    }
  | {
      type: "setVisibility";
      visibility: boolean;
    }
  | {
      type: "setMode";
      mode: CompanyDropdownSettingsMode;
    }
  | {
      type: "workingContext";
      context: "invoices" | "purchases";
    }
  | {
      type: "setSelectedCompanyName";
      companyName: string;
    };

export const navbarContextReducer = (
  state: NavbarState,
  action: NavbarContextAction
): NavbarState => {
  switch (action.type) {
    case "setCompanyDropdownSettings":
      return { ...state, companyDropdownSettings: action.settings };
    case "setVisibility":
      return {
        ...state,
        companyDropdownSettings: {
          ...state.companyDropdownSettings,
          visible: action.visibility,
        },
      };
    case "setVisibility":
      return {
        ...state,
        companyDropdownSettings: {
          ...state.companyDropdownSettings,
          visible: action.visibility,
        },
      };
    case "workingContext":
      return {
        ...state,
        companyDropdownSettings: {
          ...state.companyDropdownSettings,
          workingContext: action.context,
        },
      };
    case "setSelectedCompanyName":
      return {
        ...state,
        companyDropdownSettings: {
          ...state.companyDropdownSettings,
          companySelectedName: action.companyName,
        },
      };
    case "setMode":
      return {
        ...state,
        companyDropdownSettings: {
          ...state.companyDropdownSettings,
          mode: action.mode,
        },
      };
    default:
      return state;
  }
};
