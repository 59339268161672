import { notification } from 'antd';
import i18next from 'i18next';
import './Toast.less';
import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  ExclamationCircleOutlined,
  InfoCircleOutlined,
} from '@ant-design/icons';

export enum ToastType {
  success = 'success',
  error = 'error',
  warn = 'warn',
  info = 'info',
}

interface ToastProps {
  className: string;
  icon: any;
}

const SUCCESS_PROPS: ToastProps = { className: 'success', icon: <CheckCircleOutlined /> };
const ERROR_PROPS: ToastProps = { className: 'error', icon: <CloseCircleOutlined /> };
const INFO_PROPS: ToastProps = { className: 'info', icon: <InfoCircleOutlined /> };
const WARN_PROPS: ToastProps = { className: 'warn', icon: <ExclamationCircleOutlined /> };

const getToastProps = (type: ToastType): ToastProps => {
  switch (type) {
    case ToastType.success:
      return SUCCESS_PROPS;
    case ToastType.error:
      return ERROR_PROPS;
    case ToastType.info:
      return INFO_PROPS;
    case ToastType.warn:
      return WARN_PROPS;
    default:
      throw new Error('Invalid toast type');
  }
};
// @ts-ignore: Unreachable code error
const openNotification = (type: ToastType, message: string, description: string = null) => {
  const toastProps = getToastProps(type);

  notification[type]({
    // @ts-ignore: Unreachable code error
    message: i18next.t(`error:${message}`) || i18next.t('error:somethingWentWrong'),
    description,
    duration: 7,
    className: toastProps.className,
    icon: toastProps.icon,
  });
};

export default openNotification;
