const forge = require('node-forge');

export class EncryptionService {
  static encrypt = (key: string, data: string): string => {
    let pubKey = forge.pki.publicKeyFromPem(`-----BEGIN PUBLIC KEY-----${key}-----END PUBLIC KEY-----`);

    const encrypted = pubKey.encrypt(data, 'RSA-OAEP', {
      md: forge.md.sha256.create(),
      mgf1: {
        md: forge.md.sha1.create(),
      },
    });
    return window.btoa(encrypted);
  };
}