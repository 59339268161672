import { Select as AntSelect, SelectProps as AntSelectProps } from "antd";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { v4 as uuid } from "uuid";
import { Error } from "../../StyledComponents/warningsStyledComponents";
import "./Select.less";

import { Colors } from "../../Shared/Colors";
import DeleteCircle from "../../Shared/Icons/DeleteCircle";
import DownChevron from "../../Shared/Icons/DownChevron";
import * as S from "../../StyledComponents/basicStyledComponents";
import { generateLabel, noValueElement, optional } from "../shared/Utils";

export const { Option } = AntSelect;

interface Props extends AntSelectProps<any> {
  label?: React.ReactNode;
  error?: string;
  isReadOnly?: boolean;
  translationKey?: string;
  id?: string;
  name?: string;
  isInTable?: boolean;
  required?: boolean;
  refSelect?: any;
  disabled?: boolean;
}

export const Select: React.FunctionComponent<Props> = (props) => {
  const { t } = useTranslation();
  const [id, setId] = useState(useMemo(() => uuid(), []));
  useEffect(() => {
    if (props.id) {
      setId(props.id);
    }
  }, []);

  const {
    label,
    error,
    isReadOnly,
    translationKey,
    placeholder,
    refSelect,
    disabled,
    isInTable,
    ...restProps
  } = props;

  const checkPlaceholder = !!placeholder
    ? placeholder
    : !props.required && optional();
  const select = (
    <AntSelect
      {...restProps}
      onChange={(e) => {
        if (props.onChange) props.onChange(e ? e : null, {} as any);
      }}
      placeholder={checkPlaceholder}
      listHeight={300}
      className="custom-select"
      disabled={isReadOnly || disabled}
      showSearch
      ref={refSelect}
      suffixIcon={
        <DownChevron width="12px" height="12px" color={Colors.Gray} />
      }
      clearIcon={
        <span className="delete-circle-for-select">
          <DeleteCircle width="13px" height="13px" color={Colors.Gray} />
        </span>
      }
    />
  );

  const getValueForReadOnly = () => props.value ?? props.defaultValue;

  return (
    <S.InputWrapper isInTable={!!isInTable}>
      {
        // @ts-ignore: Unreachable code error
        generateLabel(label, restProps.required, id)
      }
      {isReadOnly && (
        <S.ReadOnlyValue>
          <>
            {!!getValueForReadOnly()
              ? t(
                  `${
                    translationKey ? translationKey : ""
                  }.${getValueForReadOnly()}`,
                  getValueForReadOnly()
                )
              : noValueElement}
          </>
        </S.ReadOnlyValue>
      )}
      <S.InputMargin error={!!error} isInTable={!!isInTable}>
        {!isReadOnly && select}
      </S.InputMargin>
      <Error>
        {error && (
          <div data-testid={`error_${id}`}>{t(`error:${error}`, error)}</div>
        )}
      </Error>
    </S.InputWrapper>
  );
};
