import { DatePicker } from "antd";
import locale from "antd/es/date-picker/locale/pl_PL";
import moment from "moment";
import "moment/locale/pl";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { v4 as uuid } from "uuid";
import { Languages } from "../../Components/Shared/Utilites/DateAndTimeUtilites";
import * as S from "../../StyledComponents/basicStyledComponents";
import { Error } from "../../StyledComponents/warningsStyledComponents";
import "../DatePicker/DatePicker.less";
import { generateLabel, noValueElement } from "../shared/Utils";

const { RangePicker } = DatePicker;

const DateRangePicker: React.FunctionComponent<any> = (props) => {
  const { t, i18n } = useTranslation();
  const dateFormat = "YYYY-MM-DD hh:mm:ss";
  let id = useMemo(() => uuid(), []);
  if (props.id) {
    id = props.id;
  }

  const getValues = () => {
    if (!props.value) {
      return undefined;
    }
    if (Array.isArray(props.value)) {
      return props.value
        ? [
            moment(props.value[0], dateFormat),
            moment(props.value[1], dateFormat),
          ]
        : undefined;
    }
    const values = Object.values(props.value);
    // @ts-ignore: Unreachable code error
    return [moment(values[0], dateFormat), moment(values[1], dateFormat)];
  };
  return (
    <>
      <S.InputWrapper isInTable={!!props.isInTable}>
        {generateLabel(props.label, props.required, id)}
        {props.isReadOnly && (
          <S.ReadOnlyValue>{props.value.dateFrom || noValueElement} - {props.value.dateTo || noValueElement} </S.ReadOnlyValue>
        )}
        {!props.isReadOnly && (
          <S.InputMargin error={!!props.error}>
            <RangePicker
              locale={i18n.language === Languages.pl_PL ? locale : undefined}
              {...props}
              value={getValues()}
              ref={props.refDate}
            />
          </S.InputMargin>
        )}
        <Error>
          {props.error && (
            <div data-testid={`error_${id}`}>
              {t(`error:${props.error}`, props.error)}
            </div>
          )}
        </Error>
      </S.InputWrapper>
    </>
  );
};

export default DateRangePicker;
