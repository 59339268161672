import React, { createContext, useReducer } from "react";
import {
  CompanyDropdownSettingsMode,
  NavbarState,
} from "./Models/NavbarContextProviderState";
import { navbarContextReducer } from "./Models/NavbarContextReducer";

const NavbarContext = createContext<{
  state: NavbarState;
  dispatch: any;
}>(undefined as any);

export function useNavbarContext() {
  return React.useContext(NavbarContext);
}

interface NavbarContextProviderProps {
  children: React.ReactNode;
}

export const NavbarContextProvider: React.FC<NavbarContextProviderProps> = (
  props
) => {
  const [state, dispatch] = useReducer(navbarContextReducer, {
    companyDropdownSettings: {
      visible: false,
      mode: CompanyDropdownSettingsMode.readonly,
      workingContext: "invoices",
    },
  });
  return (
    <NavbarContext.Provider value={{ state, dispatch }}>
      {props.children}
    </NavbarContext.Provider>
  );
};
