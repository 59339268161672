import { message } from 'antd';
import i18next from 'i18next';
import { ResponseError } from '../../Components/Shared/Models/ResponseError';

const errorMessage = (error: ResponseError | string) => {
  if (typeof error === 'string') {
    // @ts-ignore: Unreachable code error
    message.error(i18next.t(`error:${error}`, error) || i18next.t('error:unknownErrorOccurred'));
  } else {
    // @ts-ignore: Unreachable code error
    return message.error(error.response.data.fullErrorMessage || i18next.t('error:unknownErrorOccurred'));
  }
};

export default errorMessage;
