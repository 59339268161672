import styled from "styled-components";

export const EndSessionContentBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 30px;
  align-self: stretch;
  color: white;
`;

export const RemainingTime = styled.div`
  color: white;
  text-align: center;
  font-feature-settings: "clig" off, "liga" off;
  font-size: 36px;
  font-style: normal;
  font-weight: 500;
  line-height: 70px;
  letter-spacing: -0.4px;
`;

export const EndSessionTextBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: left;
  gap: 20px;
  flex: 1 0 0;
`;

export const EndSessionTitle = styled.div`
  font-feature-settings: "clig" off, "liga" off;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 32px;
  letter-spacing: -0.4px;
`;

export const EndSessionDesc = styled.div`
  font-feature-settings: "clig" off, "liga" off;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.4px;
`;

export const EndSessionsButtonBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;
  .ant-btn-custom {
    width: 100% !important;
  }
  .ant-btn-primary {
    width: 100% !important;
    height: 40px !important;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
  }
  .ant-btn-text {
    width: 100% !important;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
  }
`;
