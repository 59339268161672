import { Typography } from 'antd';
import './FittingText.less';
const { Text } = Typography;

interface FittingTextProps {
  title: React.ReactNode;
  text: React.ReactNode;
  className?: string;
}

export const FittingText: React.FC<FittingTextProps> = (props) => {
  const Title: React.FC = () => {
    return <div className="tooltip-content">{props.title}</div>;
  };

  return (
    <Text className={props.className} ellipsis={{ tooltip: <Title /> }}>
      {props.text}
    </Text>
  );
};
