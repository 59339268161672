import { useTranslation } from "react-i18next";
import { AppIcons } from "../../../Shared/Icons/AppIcons";
import { Page } from "../../../StyledComponents/basicStyledComponents";
import { tKeys } from "../../Constant/tKeys";
import {
  IconBox,
  InfoText,
  ModuleDisabledBox,
  TitleText,
} from "./ModuleDisabledStyled";

export const ModuleDisabled: React.FC = () => {
  const { t } = useTranslation();
  return (
    <Page>
      <ModuleDisabledBox>
        <IconBox>
          <AppIcons.PaperAirplaneFill viewBox="0 0 100 100" />
        </IconBox>
        <TitleText>{t(tKeys.accessDenied)}</TitleText>
        <InfoText>
          {t(tKeys.accessDeniedDesc, tKeys.accessDeniedDescText)}
        </InfoText>
      </ModuleDisabledBox>
    </Page>
  );
};
