import React, { createContext, useEffect, useReducer } from "react";
import { useLocation } from "react-router";
import { FiltersState, filtersReducer } from "./FiltersReducer";

const FiltersContext = createContext<
  { state: FiltersState; dispatch: any } | undefined
>(undefined);

export const useFiltersContext = () => {
  const context = React.useContext(FiltersContext);

  if (!context) {
    throw new Error("Out of context filters");
  }
  return context;
};

interface FiltersContextProviderProps {
  children: React.ReactNode;
}

export const FiltersContextProvider: React.FC<FiltersContextProviderProps> = (
  props
) => {
  let [state, dispatch] = useReducer(filtersReducer, {
    appliedFilters: undefined,
  });

  const location = useLocation();

  useEffect(() => {
    dispatch({ type: "clear" });
  }, [location]);

  return (
    <FiltersContext.Provider value={{ state: state, dispatch: dispatch }}>
      {props.children}
    </FiltersContext.Provider>
  );
};
