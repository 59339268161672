import React, { useEffect } from "react";

const SessionTimeout = () => {
  useEffect(() => {
    const resetActivity = () => {
      const currentTime = new Date().getTime();
      localStorage.setItem("lastActivity", `${currentTime}`);
    };

    window.addEventListener("mousemove", resetActivity);
    window.addEventListener("keydown", resetActivity);

    resetActivity();

    return () => {
      window.removeEventListener("mousemove", resetActivity);
      window.removeEventListener("keydown", resetActivity);
    };
  }, []);

  return null;
};

export default SessionTimeout;
