import axios, { AxiosResponse } from "axios";
import { saveAs } from "file-saver";
import { useQuery } from "react-query";
import { getFileNameFromHeaders } from "../../../../Shared/Utils/ServiceUtils";

export const useDownloadFileByGet = (
  downloadFn: () => Promise<AxiosResponse<any>>,
  uniqueKey: string,
  fileNameSuffix?: string
) => {
  return useQuery(
    ["downloadFileKey", uniqueKey],
    () => {
      return downloadFn();
    },
    {
      onSuccess: (data: any) => {
        const url = new Blob([data.data], {
          type: data.headers["content-type"],
        });
        const fileName = getFileNameFromHeaders(data.headers);
        const parts = fileName.split(".");
        const fileNameWithoutExtension = parts[0];
        const fileExtension = parts[1];
        if (fileNameSuffix) {
          saveAs(
            url,
            `${fileNameWithoutExtension}-${fileNameSuffix}.${fileExtension}`
          );
        } else {
          saveAs(url, `${fileName}`);
        }
      },
      refetchOnWindowFocus: false,
      enabled: false,
      cacheTime: 300,
    }
  );
};

export const useDownloadFileByGetForUrl = (
  url: string,
  fileNameSuffix?: string
) => {
  return useQuery(
    ["downloadFileKey", url],
    () => {
      return axios.get(url, { responseType: "blob" });
    },
    {
      onSuccess: (data: any) => {
        const url = new Blob([data.data], {
          type: data.headers["content-type"],
        });
        const fileName = getFileNameFromHeaders(data.headers);
        const parts = fileName.split(".");
        const fileNameWithoutExtension = parts[0];
        const fileExtension = parts[1];
        if (fileNameSuffix) {
          saveAs(
            url,
            `${fileNameWithoutExtension}-${fileNameSuffix}.${fileExtension}`
          );
        } else {
          saveAs(url, `${fileName}`);
        }
      },
      refetchOnWindowFocus: false,
      enabled: false,
      cacheTime: 300,
    }
  );
};
