import React, { createContext, useContext, useEffect, useState } from "react";
import { useOverdueConfiguration } from "./AppProvider.service";
import { useTranslation } from "react-i18next";
import { tKeys } from "../Constant/tKeys";
import axios from "../../Shared/axios";
import { LoginService } from "../Login/Login.service";

enum KsefEnv {
  PROD = "PROD",
  DEMO = "DEMO",
  TEST = "TEST",
}

export interface AppProviderModel {
  invoiceOverdueDaysLimit: number | undefined;
  invoiceOverdueTranslationObject: object;
  ksefVersion: KsefEnv | undefined;
  personalDataLinkVisible: boolean;
}

const AppProviderContext = createContext<AppProviderModel | undefined>(
  undefined
);

export const useAppProvider = () => {
  const context = useContext(AppProviderContext);
  if (!context) {
    throw new Error("Out of AppProvider context");
  }
  return context;
};

interface AppProviderProps {
  children: React.ReactNode;
}

interface KsefEnvType {
  ksefEnvironment: KsefEnv;
}

export const AppProvider: React.FC<AppProviderProps> = (props) => {
  const { t } = useTranslation();
  const [overdue, setOverdue] = useState<number | undefined>(undefined);

  useOverdueConfiguration(!!!overdue, (data) => {
    setOverdue(data.invoiceOverdueDaysLimit);
  });

  const getDay = () => (overdue === 1 ? t(tKeys.day) : t(tKeys.days));

  const getInvoiceOverdueTranslationObject = () => ({
    days: `${overdue} ${getDay()}`,
  });
  const [ksefVersion, setKsefVersion] = useState<KsefEnv | undefined>();
  const [personalDataLinkVisible, setPersonalDataLinkVisible] = useState(false);

  useEffect(() => {
    axios
      .get<KsefEnvType>("api/environments/ksef")
      .then((res) => setKsefVersion(res.data?.ksefEnvironment));

    LoginService.shouldShowProcessingPersonalData().then((res) =>
      setPersonalDataLinkVisible(res.data?.visible)
    );
  }, []);

  return (
    <AppProviderContext.Provider
      value={{
        invoiceOverdueDaysLimit: overdue,
        invoiceOverdueTranslationObject: getInvoiceOverdueTranslationObject(),
        ksefVersion: ksefVersion,
        personalDataLinkVisible,
      }}
    >
      {props.children}
    </AppProviderContext.Provider>
  );
};
