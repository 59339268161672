import axios from "../../Shared/axios";

export const featuresUrl = "api/features";
export type DisabledFeatureType =
  | "OUTGOING_INVOICES"
  | "INCOMING_INVOICES"
  | "";
export class GlobalContextService {
  static getDisabledFeatures() {
    return axios.get<{ feature: DisabledFeatureType }>(
      `${featuresUrl}/disabled`
    );
  }

  static getApplicationExtraFeature() {
    return axios.get<{features:string[]}>(`api/extra-features`);
  }
}
