import { useMutation, useQuery } from "react-query";
import axios from "../../Shared/axios";
import { useGlobalContext } from "../GlobalContextProvider/GlobalContextProvider";
import { AppProfileOptions } from "../GlobalContextProvider/Models/AppProfileOptions";
import { EncryptionService } from "../Shared/EncryptionService";
import { LoggedUserType } from "../UserContextProvider/Models/LoggedUserType";

export class LoginService {
  static login(username: string, password: string) {
    return axios.post(`api/authentication/authenticate`, {
      username,
      password,
    });
  }

  static getEncryptionPublicKey() {
    return axios.get<{ value: string }>(`api/encryption`);
  }

  static refreshToken() {
    return axios.get(`/api/authentication/accessToken`);
  }

  static getProfile() {
    return axios.get<AppProfileOptions>(`/api/authentication/profile`);
  }

  static shouldShowProcessingPersonalData() {
    return axios.get("/api/privacy-policy");
  }

  static checkAuthentication() {
    return axios.get<LoggedUserType>("api/authentication");
  }

  static getAppVersion() {
    return axios.get<{ version: string }>("/api/application-version");
  }
}

export const useGetAppVersion = (enabled?: boolean, onSuccess?: (version: string) => void) => {
  return useQuery(["appVersion"], LoginService.getAppVersion, {
    onSuccess: ({ data }) => {
      if (onSuccess) return onSuccess(data.version);
    },
    enabled: enabled,
    refetchOnMount: true,
    cacheTime: 5000,
    staleTime: 5000
  });
};

export const useGetAppProfile = (enabled:boolean,
  onSuccess: (appProfileOption: AppProfileOptions) => void
) => {
  return useQuery(["appProfile"], LoginService.getProfile, {
    onSuccess: ({ data }) => onSuccess(data),
    refetchOnMount: true,
    cacheTime: 5000,
    enabled
  });
};

export const useGetEncryptionPublicKey = (
  onSuccess: (EncryptionPublicKey: string) => void
) => {
  return useQuery(
    ["EncryptionPublicKey"],
    LoginService.getEncryptionPublicKey,
    {
      onSuccess: ({ data }) => onSuccess(data.value),
      refetchOnMount: false,
      enabled: false,
      cacheTime: 5000,
    }
  );
};

const getEncryptedPassword = async (
  plainPassword: string,
  encryptionKey?: string
): Promise<string> => {
  let encryptedPasswords = "";
  if (encryptionKey) {
    encryptedPasswords = await EncryptionService.encrypt(
      encryptionKey,
      plainPassword
    );
  }
  return encryptedPasswords;
};

export const useLogin = (
  onSuccess?: (data: LoggedUserType) => void,
  onError?: () => void
) => {
  const globalContext = useGlobalContext();
  return useMutation(
    async (data: { username: string; password: string }) => {
      const encryptedPasswords = await getEncryptedPassword(
        data.password,
        globalContext.encryptionKey
      );
      await LoginService.login(data.username, encryptedPasswords);
      return LoginService.checkAuthentication();
    },
    {
      onSuccess: ({ data }) => {
        if (onSuccess) onSuccess(data);
      },
      onError,
    }
  );
};

export const useCheckAuthenticationUser = (
  onSuccess: (user: LoggedUserType) => void
) => {
  return useQuery(["checkAuthentication"], LoginService.checkAuthentication, {
    onSuccess: ({ data }) => onSuccess(data),
    refetchOnMount: false,
    enabled: false,
    cacheTime: 1000,
  });
};
