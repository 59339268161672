import React, { useMemo } from 'react';
import { v4 as uuid } from 'uuid';
import { Radio as AntRadio, RadioGroupProps as AntRadioGroupProps, RadioProps as AntRadioProps } from 'antd';
import './Radio.less';
export const { Group } = AntRadio;

interface Props extends AntRadioProps {
  label?: React.ReactNode;
  error?: string;
  name?: string;
  required?: boolean;
}

interface RadioComposition {
  Group: React.FunctionComponent<AntRadioGroupProps>;
}

const Radio: React.FunctionComponent<Props> & RadioComposition = (props) => {
  const { label, error } = props;

  const id = useMemo(() => uuid(), []);

  return (
    <div>
      {label && <label htmlFor={id}>{label}</label>}
      <AntRadio {...props} id={id} />
      {error && <div>{error}</div>}
    </div>
  );
};

Radio.Group = Group;

export default Radio;
