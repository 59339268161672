import styled from 'styled-components';

export const Error = styled.div`
  font-size: 10px;
  color: #c52a1a;
  text-align: left;
  margin-top:2px;
  line-height: 1;
`;

export const ErrorColor = styled.span`
  color: #c52a1a;
  font-weight:900;
`;

export const WarrningTag = styled.div`
  background: #ffbf1f;
  border-radius: 12px;
  font-weight: 600;
  padding: 1px 10px 1px 10px;
  display: inline;
  font-size: 12px;
  margin: 2px 10px 0 10px;
  line-height: 18px;
`;
