import { Input as AntInput } from "antd";
import { TextAreaProps as AntTextAreaProps } from "antd/es/input";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { v4 as uuid } from "uuid";
import * as S from "../../../StyledComponents/basicStyledComponents";
import { Error } from "../../../StyledComponents/warningsStyledComponents";
import { generateLabel, noValueElement } from "../../shared/Utils";

const { TextArea: AntTextArea } = AntInput;

export interface TextAreaProps extends AntTextAreaProps {
  label?: React.ReactNode;
  error?: string;
  required?: boolean;
  isInTable?: boolean;
  isReadOnly?: boolean;
  refTextArea?: any;
}

const TextArea: React.FunctionComponent<TextAreaProps> = (props) => {
  const { t } = useTranslation();
  const { label, error, required, isReadOnly, refTextArea, ...restProps } = props;

  const id = useMemo(() => uuid(), []);

  return (
    <S.InputWrapper isInTable={!!props.isInTable}>
      {generateLabel(label, !!required, id)}
      {isReadOnly && (
        <S.ReadOnlyValue id={restProps.id}>
          <>{props.value || noValueElement} </>
        </S.ReadOnlyValue>
      )}
      <S.InputMargin error={!!error}>
        {!isReadOnly && (
          <AntTextArea ref={refTextArea} {...restProps} />
        )}
      </S.InputMargin>
      <Error>
        {error && (
          <div data-testid={`error_${props.id || id}`}>
            {t(`error:${error}`, error)}
          </div>
        )}
      </Error>
    </S.InputWrapper>
  );
};

export default TextArea;
