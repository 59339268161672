import "./webpackNonce.js";
import "./index.css";
import ReactDOM from "react-dom/client";
import { QueryClient, QueryClientProvider } from "react-query";
import { HashRouter, Route, Routes } from "react-router-dom";
import App from "./Components/App/App";
import { FeatureContextProvider } from "./Components/FeatureContextProvider/FeatureContextProvider";
import { GlobalContextProvider } from "./Components/GlobalContextProvider/GlobalContextProvider";
import { UserContextProvider } from "./Components/UserContextProvider/UserContextProvider";
import "./index.css";
import "./webpackNonce.js";
import { Login } from "./Components/Login/Login";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
      retry: false,
    },
  },
});

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <HashRouter>
    <QueryClientProvider client={queryClient}>
      <GlobalContextProvider>
        <FeatureContextProvider>
          <UserContextProvider>
            <Routes>
              <Route path="/*" element={<App />} />
              <Route path="/login" element={<Login />} />
            </Routes>
          </UserContextProvider>
        </FeatureContextProvider>
      </GlobalContextProvider>
    </QueryClientProvider>
  </HashRouter>
);
