import i18next from 'i18next';
import { tKeys } from '../../Components/Constant/tKeys';
import { NoValue, UppercaseText } from '../../StyledComponents/basicStyledComponents';
import { ErrorColor } from '../../StyledComponents/warningsStyledComponents';
import openNotification, { ToastType } from '../Toast/Toast';

export const redStar = <ErrorColor>*</ErrorColor>;

export const noValue = () => i18next.t('noValue', 'No value');

export const optional = () => i18next.t(tKeys.optional, tKeys.optionalText);
// @ts-ignore: Unreachable code error
export const noValueElement = () => <NoValue>{i18next.t('noValue', 'No value')}</NoValue>;

export const generateLabel = (label: any, required: boolean, id: any) => {
  return (
    label && (
      <UppercaseText>
        <label htmlFor={id}>
          {required && redStar} {label}
        </label>
      </UppercaseText>
    )
  );
};

export const copyToClipboard = (e: Event, text: string, t: any) => {
  e.stopPropagation();
  navigator.clipboard.writeText(text);
  openNotification(ToastType.success, t(tKeys.copiedToClipboard, tKeys.copiedToClipboardText));
};
