import { Button as AntButton, ButtonProps as AntButtonProps } from "antd";
import React from "react";
import "./Button.less";

interface ButtonProps extends AntButtonProps {
  textNotModified?: string;
}

const Button: React.FunctionComponent<ButtonProps> = (props) => {
  const textWithIcon = props.type == "text" && !!props.icon;

  return (
    <div className={textWithIcon ? "ant-btn-custom-icon" : "ant-btn-custom"}>
      <AntButton size={"middle"} {...props}>
        {props.textNotModified
          ? props.textNotModified
          : props?.children?.toString()?.toLocaleLowerCase()?.includes("ksef")
          ? props?.children
          : // @ts-ignore: Unreachable code error
            props?.children?.toString()?.charAt(0).toUpperCase() +
            // @ts-ignore: Unreachable code error
            props?.children?.toString()?.slice(1)?.toLocaleLowerCase()}
      </AntButton>
    </div>
  );
};

export default Button;
