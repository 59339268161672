import { CheckboxGroupFilter, FilterToRemove, NavFilterType } from './Models/FilterType';

type FiltersAction =
  | { type: 'setFilters'; filters: { [key: string]: NavFilterType } }
  | { type: 'removeFilter'; filter: FilterToRemove }
  | { type: 'clear' };

export interface FiltersState {
  appliedFilters: any;
}

export const filtersReducer = (state: FiltersState, action: FiltersAction): FiltersState => {
  switch (action.type) {
    case 'setFilters':
      return { appliedFilters: action.filters };
    case 'removeFilter':
      if ((action.filter as FilterToRemove).arrayValue) {
        const actual = state.appliedFilters[action.filter.key] as CheckboxGroupFilter;
        const newValues = actual.values.filter((v) => v.value != (action.filter as FilterToRemove).arrayValue);
        return {
          appliedFilters: { ...state.appliedFilters, [action.filter.key]: { ...actual, values: [...newValues] } },
        };
      }
      if (action.filter.key) {
        return { appliedFilters: { ...state.appliedFilters, [action.filter.key]: null } };
      }

      return { ...state };
    case 'clear':
      return { appliedFilters: null };
  }
};
