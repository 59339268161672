import { Space } from "antd";
import * as React from "react";
import LogoPwc from "../../Shared/Pictograms/LogoPwc";
import { useUserContext } from "../UserContextProvider/UserContextProvider";
import CompanySelect from "./Components/CompoanySelect/CompanySelect";
import LanguageSwitcher from "./Components/LanguageSwitcher/LanguageSwitcher";
import Menu from "./Components/Menu/Menu";
import "./Navbar.less";
import { CompanyDropdownSettingsMode } from "./NavbarContext/Models/NavbarContextProviderState";
import { useNavbarContext } from "./NavbarContext/NavbarContextProvider";
import TabsPanel from "./Tabs";

const Navbar: React.FC = () => {
  const userContext = useUserContext();
  const { state } = useNavbarContext();

  return (
    <div className="navbar-content">
      <div className="logo-tabs">
        <a className="logo" href="#/invoices">
          <LogoPwc width="186px" height="38px" />
        </a>
        <div className="line"></div>
        <div className="tabs-panel-col">
          <TabsPanel />
        </div>
      </div>
      <div className="firm-and-user-content">
        <Space className="floatRight">
          {state.companyDropdownSettings.visible && (
            <>
              {state.companyDropdownSettings.mode ===
                CompanyDropdownSettingsMode.enabled &&
                userContext.user?.assignedCompanies &&
                userContext.user?.assignedCompanies.length > 0 && (
                  <div className="company-select">
                    {
                      <CompanySelect
                        moduleName={
                          state.companyDropdownSettings.workingContext
                        }
                      />
                    }
                  </div>
                )}

              {state.companyDropdownSettings.mode ===
                CompanyDropdownSettingsMode.readonly && (
                <span className="company-select-name">
                  {state.companyDropdownSettings.companySelectedName}
                </span>
              )}
            </>
          )}
          <div className="line line2"></div>
          <LanguageSwitcher />
        </Space>

        {userContext.user && (
          <Menu
            userName={userContext.user.username}
            onLogout={userContext.logout}
            
          />
        )}
      </div>
    </div>
  );
};

export default Navbar;
