export enum MenuItemType {
  ACTION = 'action',
  DIVIDER = 'divider',
  CONFIRM_ACTION = 'confirm action',
}

export interface MenuItem {
  key: string;
  label?: React.ReactNode;
  type: MenuItemType;
  icon?: React.ReactNode;
  action?: () => void;
  hidden?: boolean;
  confirmTitle?: string;
  onConfirm?: () => void | undefined;
  okText?: string;
  cancelText?: string;
}
