import axios from "axios";
import { ResponseCode } from "../Components/Shared/Models/ResponseCode";
import { ResponseError } from "../Components/Shared/Models/ResponseError";
import openNotification, { ToastType } from "../DesignSystem/Toast/Toast";
import globalRouter from "../globalRouter";

const instance = axios.create();

instance.interceptors.request.use(
  (requestConfig) => {
    requestConfig.headers = {
      ...requestConfig.headers,
    };

    // remove "/" sign from the beggining of the url
    // @ts-ignore: Unreachable code error
    if (requestConfig.url[0] === "/") {
      // @ts-ignore: Unreachable code error
      requestConfig.url = requestConfig.url.substring(1);
    }

    // set url to relative one in case app is served from a catalog
    requestConfig.url = `${window.location.pathname}${requestConfig.url}`;

    const currentTimestamp = Date.now();
    requestConfig.params = {
      ...requestConfig.params,
      cacheBuster: currentTimestamp,
    };

    return requestConfig;
  },
  (error) => {
    return Promise.reject(error);
  }
);
instance.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error: ResponseError) {
    if (error.response.config.url.endsWith("/api/invoices/file")) {
      return Promise.reject(error);
    }
    switch (error.response.status) {
      case ResponseCode.Unauthorized:
        if (globalRouter.navigate) globalRouter.navigate("/login");
        break;
      default:
        const isJsonBlob = (data: any) =>
          data instanceof Blob && data.type === "application/json";
        if (isJsonBlob(error.response?.data)) {
          const getBlobText = async (data: Blob) => await data.text();
          getBlobText(error.response?.data as any as Blob).then((data) => {
            const responseJson = JSON.parse(data);
            openNotification(ToastType.error, responseJson.errorMessage);
          });
        } else {
          openNotification(ToastType.error, error.response.data.errorMessage);
        }
        return Promise.reject(error);
    }
  }
);

export default instance;
