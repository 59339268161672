import { Tabs } from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { H6 } from "../../StyledComponents/headingsStyledComponents";
import { tKeys } from "../Constant/tKeys";
import { useFeatureContextProvider } from "../FeatureContextProvider/FeatureContextProvider";

const { TabPane } = Tabs;

const TabsPanel: React.FC = () => {
  const { t } = useTranslation();
  let navigate = useNavigate();
  const location = useLocation();
  const useFeatureContext = useFeatureContextProvider();

  const [activeKey, setActiveKey] = useState(location.pathname);

  useEffect(() => {
    if (location.pathname.includes("/invoices")) {
      setActiveKey(`/invoices`);
    } else if (location.pathname.includes(`/purchases`)) {
      setActiveKey("/purchases");
    } else if (location.pathname.includes("/eventLog")) {
      setActiveKey("/eventLog");
    } else if (location.pathname.includes("/entities")) {
      setActiveKey("/entities");
    } else {
      setActiveKey(location.pathname);
    }
  }, [location]);

  const callback = (key: any) => {
    setActiveKey(key);
    navigate(key);
  };

  const items = [
    {
      label: <H6 data-testid="link-outgoing-invoices">{t(tKeys.outgoing)}</H6>,
      key: `/invoices`,
    },
    {
      label: <H6 data-testid="link-incoming-invoices">{t(tKeys.incoming)}</H6>,
      key: `/purchases`,
    },
    {
      label: (
        <H6 data-testid="link-companies">
          {t(tKeys.entities.companies, tKeys.entities.companiesText)}
        </H6>
      ),
      key: `/entities`,
    },
  ];

  return (
    <div className="nav-tabls">
      <Tabs activeKey={activeKey} onTabClick={callback} items={items} />
    </div>
  );
};

export default TabsPanel;
