import i18n from 'i18next';
import { Cookies } from 'react-cookie';
import { initReactI18next } from 'react-i18next';
import countriesEN from './Locales/en/countries.json';
import errorEN from './Locales/en/error.json';
import invoiceFormFieldsEN from './Locales/en/invoiceFormFields.json';
import mainEn from './Locales/en/main.json';
import countriesPL from './Locales/pl/countries.json';
import errorPL from './Locales/pl/error.json';
import invoiceFormFieldsPL from './Locales/pl/invoiceFormFields.json';
import mainPl from './Locales/pl/main.json';

const languageCookieName = 'NG_TRANSLATE_LANG_KEY';
const language = new Cookies().get(languageCookieName);

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources: {
      en_GB: {
        translation: mainEn,
        error: errorEN,
        country: countriesEN,
        invoiceFormFields: invoiceFormFieldsEN,
      },
      pl_PL: {
        translation: mainPl,
        error: errorPL,
        country: countriesPL,
        invoiceFormFields: invoiceFormFieldsPL,
      },
    },

    lng: language,
    react: { useSuspense: false },
    fallbackLng: 'en_GB',
    //keySeparator: true,
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
