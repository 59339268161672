import { Dispatch, SetStateAction } from "react";
import { Modal } from "../../../../../DesignSystem";
import "./EndSessionModal.less";
import { EndSessionTimer } from "./EndSessionTimer/EndSessionTimer";

interface EndSessionModalProps {
  modalOpen: boolean;
  setOpenModal: Dispatch<SetStateAction<boolean>>;
}

export const EndSessionModal: React.FC<EndSessionModalProps> = (props) => {
  return (
    <Modal
      open={props.modalOpen}
      hideCancelButton={true}
      className="end-session-modal-box"
      closable={false}
      destroyOnClose
    >
      <EndSessionTimer setOpenModal={props.setOpenModal} />
    </Modal>
  );
};
