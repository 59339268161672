import { CaretDownOutlined } from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import { Cookies } from "react-cookie";
import { useQueryClient } from "react-query";
import { Button, DropdownMenu } from "../../../../DesignSystem";
import { MenuItemType } from "../../../../DesignSystem/Dropdown/DropdownMenu/DropdownMenu.types";
import * as S from "../../../../StyledComponents/basicStyledComponents";
import i18n from "../../../../i18n";
import { Languages } from "../../../Shared/Utilites/DateAndTimeUtilites";

const languageCookieName = "NG_TRANSLATE_LANG_KEY";

const LanguageSwitcher: React.FunctionComponent<any> = () => {
  const [selectedLanguage, setSelectedLanguage] = useState(
    new Cookies().get(languageCookieName) || Languages.pl_PL
  );
  const queryClient = useQueryClient();

  const switchLanguage = (language: Languages) => {
    new Cookies().set(languageCookieName, language);
    setSelectedLanguage(language);
    i18n.changeLanguage(language);
    queryClient.invalidateQueries({
      predicate: (query) => !query.queryKey.includes("invoice_form"),
    });
  };

  const dropdownItems = [
    {
      key: "en",
      type: MenuItemType.ACTION,
      label: "EN",
      action: () => switchLanguage(Languages.en_GB),
      hidden: selectedLanguage === Languages.en_GB,
    },
    {
      key: "pl",
      type: MenuItemType.ACTION,
      label: "PL",
      action: () => switchLanguage(Languages.pl_PL),
      hidden: selectedLanguage === Languages.pl_PL,
    },
  ];

  useEffect(() => {
    switchLanguage(selectedLanguage);
  }, []);
  const getFlagAndLabel = (): { label: string } => {
    switch (selectedLanguage) {
      case Languages.pl_PL:
        return { label: "PL" };
      case Languages.en_GB:
        return { label: "EN" };
      default:
        return { label: "PL" };
    }
  };

  return (
    <>
      {selectedLanguage && (
        <DropdownMenu
          menuItems={dropdownItems}
          triggerElement={
            <S.LanguageSwitcher>
              <Button
                type="text"
                icon={
                  <div className="language-switcher">
                    {getFlagAndLabel()?.label}
                    <span className="caret" />
                    <CaretDownOutlined />
                  </div>
                }
              />
            </S.LanguageSwitcher>
          }
        />
      )}
    </>
  );
};

export default LanguageSwitcher;
