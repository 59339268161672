export enum Colors {
  White = "#FFFFFF",
  Gray = "#7D7D7D",
  Lightest = "#F2F2F2",
  Light = "#BDBDBD",
  Orange = "#d04a02",
  Error = "#C52A1A",
  Tangerine = "#EB8C00",
  Success = "#22992E",
  Dark = "#464646",
  Darker = "#2D2D2D",
  Yellow = "#FFB600",
  Lighter = "#DEDEDE",
  BlueDarker = "#003DAB",
  Rose = "#D93954",
  LightBlue = "#197ACF",
  Purple = "#5B5EA6",
  Green = "#009473",
  Neutral = "#696969",
  Neutral20 = "#252525",
  Neutral17 = "#474747",
  Warning = "#FFBF1F",
  DataBlueDark = "#0060D7",
  Red = "#DC7F76",
  Layout = "#fcfcfc",
  Placeholder = "#bfbfbf"
}
