import loadable from "@loadable/component";
import { Layout } from "antd";
import React from "react";
import { Navigate, Route, Routes, useNavigate } from "react-router";
import { useFeatureContextProvider } from "../FeatureContextProvider/FeatureContextProvider";
import { NavbarContextProvider } from "../Navbar/NavbarContext/NavbarContextProvider";
import { ComponentDependOnFeatureEnabled } from "../Shared/ModuleDisabled/ComponentDependOnModuleEnabled/ComponentDependOnModuleEnabled";
import { useSetTitle } from "../SharedHooks/useSetTitle";
import Navbar from "./../Navbar/Navbar";
import { FiltersContextProvider } from "./../Shared/LeftSidebar/Filters/FiltersContext";
import LeftSiteBar from "./../Shared/LeftSidebar/LeftSidebar";
import { LeftSidebarContextProvider } from "./../Shared/LeftSidebar/LeftSidebarContext";
import { useUserContext } from "./../UserContextProvider/UserContextProvider";
import "./App.less";
import { AppFooter } from "./Components/AppFooter";
import SessionTimeoutManager from "./Components/TokenRefresher/SessionTimerManager";
import { AppProvider } from "./AppProvider";
import globalRouter from "../../globalRouter";

const { Header, Content } = Layout;

const InvoicesMain = loadable(() => import("./../Invoices/Invoices"), {
  resolveComponent: (components) => components.InvoicesMain,
});
const Purchases = loadable(() => import("../Purchases/Purchases"));
const Entities = loadable(() => import("./../Entities/Entities"));
const NewEntity = loadable(() => import("../Entities/EntityForm/EntityForm"));
const EventLog = loadable(() => import("../EventLog/EventLog"));
const Settings = loadable(() => import("../Settings/Settings"));
const UserProfile = loadable(() => import("../UserProfile/UserProfile"));

const App: React.FC = () => {
  const { user, useTrySetUserIfNotExist } = useUserContext();
  useSetTitle("eFaktury");
  useTrySetUserIfNotExist();
  const useFeatureContext = useFeatureContextProvider();

  const navigate = useNavigate();
  globalRouter.navigate = navigate;

  return (
    <AppProvider>
      <LeftSidebarContextProvider>
        <FiltersContextProvider>
          <LeftSiteBar />
          <Layout className="layout">
            {user?.id && (
              <NavbarContextProvider>
                <Header>
                  <Navbar />
                </Header>
                <Layout>
                  <Content>
                    <Routes>
                      <Route
                        path="invoices/*"
                        element={
                          <ComponentDependOnFeatureEnabled
                            componentEnabled={
                              useFeatureContext.state.outgoingEnabled
                            }
                            ComponentToDisplay={<InvoicesMain />}
                          />
                        }
                      />
                      <Route
                        path="purchases/*"
                        element={
                          <ComponentDependOnFeatureEnabled
                            componentEnabled={
                              useFeatureContext.state.incomingEnabled
                            }
                            ComponentToDisplay={<Purchases />}
                          />
                        }
                      />
                      <Route path="entities" element={<Entities />} />
                      <Route path="entities/new" element={<NewEntity />} />
                      <Route
                        path="entities/:companyId"
                        element={<NewEntity />}
                      />
                      <Route path="eventLog/*" element={<EventLog />} />
                      <Route path="settings/*" element={<Settings />} />
                      <Route path="userProfile" element={<UserProfile />} />
                      <Route
                        path={"/"}
                        element={<Navigate to={"invoices"} replace />}
                      />
                    </Routes>
                  </Content>
                </Layout>
              </NavbarContextProvider>
            )}
            <AppFooter />
          </Layout>
        </FiltersContextProvider>
        <SessionTimeoutManager />
      </LeftSidebarContextProvider>
    </AppProvider>
  );
};

export default App;
