import moment from "moment";
import { Cookies } from "react-cookie";
const languageCookieName = "NG_TRANSLATE_LANG_KEY";

export enum Languages {
  en_GB = "en_GB",
  pl_PL = "pl_PL",
}

export const getLocalDateAndTime = (
  date: Date
): { date: string; time: string } => {
  const language = new Cookies().get(languageCookieName) ?? Languages.en_GB;
  const dateLanguage = language.replace("_", "-");
  const dateString = date.toLocaleDateString(dateLanguage);
  const time = date.toLocaleTimeString(dateLanguage);
  return { date: dateString, time };
};

export const isISODate = (str: string): boolean =>
  moment(str, moment.ISO_8601, true).isValid();
