import { useEffect } from "react";
import { useQuery } from "react-query";
import globalRouter from "../../../../globalRouter";
import { LoginService } from "../../../Login/Login.service";
import { getEnvPath } from "../../../Login/Login";

interface TokenRefresherProps {}

export const TokenRefresher: React.FC<TokenRefresherProps> = (props) => {
  const getTokenFromUrl = (): string | undefined => {
    const dividedUrl = window.location.href.split("token=");
    let token = undefined;
    const extraCharacters = 2;
    if (dividedUrl.length == 2) {
      token = dividedUrl[1].slice(0, dividedUrl[1].length - extraCharacters);
    }
    return token;
  };

  const token = getTokenFromUrl();
  useEffect(() => {
    if (!!token) {
      if (globalRouter.navigate) {
        const path = window.location.pathname;
        window.location.replace(`${getEnvPath(path)}#/invoices`);
      }
    }
  }, []);
  const { refetch: refetchToken } = useQuery(
    ["token"],
    async () => {
      const { data: token } = await LoginService.refreshToken();
      return token as any;
    },
    {
      onSuccess: (data: any) => {},
      refetchOnWindowFocus: false,
      enabled: false,
      cacheTime: 0,
      staleTime: 0,
    }
  );

  const periodOfRefreshingToken = 1000 * 60 * 5;

  useEffect(() => {
    const timer = setInterval(() => {
      refetchToken();
    }, periodOfRefreshingToken);
    return () => clearInterval(timer);
  }, []);

  return <></>;
};