const DeleteCircle = ({ width = '14px', height = '14px', color = '#C52A1A' }) => {
  return (
    <svg
      width={width}
      height={height}
      stroke="current"
      viewBox="0 0 32 32"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="circle-delete">
        <path
          fill={color}
          d="M16.0002,0 C24.8362,0 32.0002,7.164 32.0002,16 C32.0002,24.836 24.8362,32 16.0002,32 C7.16286667,32 0.0002,24.836 0.0002,16 C0.0002,7.164 7.16286667,0 16.0002,0 Z M23.8282,21 L18.8282,16 L23.8282,11 L21.0002,8.172 L16.0002,13.172 L11.0002,8.172 L8.1722,11 L13.1722,16 L8.1722,21 L11.0002,23.828 L16.0002,18.828 L21.0002,23.828 L23.8282,21 Z"
          id="Combined-Shape"
        ></path>
      </g>
    </svg>
  );
};
export default DeleteCircle;
