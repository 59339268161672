import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { Button } from "../../../../DesignSystem";
import Divider from "../../../../DesignSystem/Divider/Divider";
import { MenuItemType } from "../../../../DesignSystem/Dropdown/DropdownMenu/DropdownMenu.types";
import { tKeys } from "../../../Constant/tKeys";
import { AppVersion } from "../../../Shared/AppVersion/AppVersion";
import { useDownloadFileByGetForUrl } from "../../../Shared/InvoicesTables/CustomHooks/UseDownloadFileByGet";
import "./Menu.less";

interface MenuProps {
  userName: string;
  onLogout: any;
}

const Menu: React.FunctionComponent<MenuProps> = ({ userName, onLogout }) => {
  const { t } = useTranslation();
  let navigate = useNavigate();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const ref = useRef();

  const queryForManual = useDownloadFileByGetForUrl("api/help/manual");
  useEffect(() => {
    const checkIfClickedOutside = (e: any) => {
      const current = ref.current as any as Node;
      if (isMenuOpen && ref.current && !current.contains(e.target as Node)) {
        setIsMenuOpen(false);
      }
    };
    document.addEventListener("click", checkIfClickedOutside);
    return () => {
      document.removeEventListener("click", checkIfClickedOutside);
    };
  }, [isMenuOpen]);
  const menuItems = [
    {
      key: t(tKeys.myProfile.header, tKeys.myProfile.headerText),
      type: MenuItemType.ACTION,
      label: t(tKeys.myProfile.header, tKeys.myProfile.headerText),
      action: () => {
        navigate(`/userProfile`);
      },
    },
    {
      key: (
        <span data-testid="menu-settings">
          {t(tKeys.settings, tKeys.settings)}
        </span>
      ),
      type: MenuItemType.ACTION,
      label: t(tKeys.menu.settings, tKeys.menu.settingsText),
      action: () => {
        navigate(`/settings/roleAndPermissions`);
      },
    },
    {
      key: t(tKeys.eventLog.eventLog, tKeys.eventLog.eventLogText),
      type: MenuItemType.ACTION,
      label: t(tKeys.eventLog.eventLog, tKeys.eventLog.eventLogText),
      action: () => {
        navigate(`/eventLog`);
      },
    },
    {
      key: t(tKeys.instruction),
      type: MenuItemType.ACTION,
      label: t(tKeys.instruction),
      action: () => {
        queryForManual.refetch();
      },
    },
  ];

  return (
    <>
      <div
        className="user"
        ref={ref as any}
        data-testid="menu-user"
        onClick={() => setIsMenuOpen(!isMenuOpen)}
      >
        <div className="content">{userName?.[0].toUpperCase()}</div>
        {isMenuOpen && (
          <div className="dropdown-content">
            <div className="menu-items">
              {menuItems.map((i, index) => {
                return (
                  <div
                    key={`menu_item_${index}`}
                    className="menu-item"
                    onClick={i.action}
                  >
                    {i.key}
                  </div>
                );
              })}
            </div>

            <div className="logout">
              <Button
                data-testid="menu-user-log-out"
                type="primary"
                onClick={onLogout}
              >
                {t(tKeys.button.logOut, tKeys.button.logOutText)}
              </Button>
            </div>
            <Divider className="divider" />
            <div className="version-box">
              <div className="version-box-text">
                <AppVersion />
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default Menu;
