import { useQuery } from "react-query";
import axios from "../../Shared/axios";

export interface OverdueConfigModel {
  invoiceOverdueDaysLimit: number;
}

export const baseUrl = "api/invoices";
export class AppProviderService {
  static getOverdueConfiguration() {
    return axios.get<OverdueConfigModel>(`${baseUrl}/overdue-configuration`);
  }
}

export const useOverdueConfiguration = (enabled:boolean,
  onSuccess?: (data: OverdueConfigModel) => void
) => {
  return useQuery(
    ["overdue-configuration"],
    async () => {
      const result = await AppProviderService.getOverdueConfiguration();
      return result.data;
    },
    {
      enabled,
      onSuccess: (response) => {
        onSuccess && onSuccess(response);
      },
    }
  );
};
