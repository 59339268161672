import { DatePicker } from "antd";
import locale from "antd/es/date-picker/locale/pl_PL";
import { RangePickerProps } from "antd/lib/date-picker/generatePicker";
import i18next from "i18next";
import moment from "moment";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { v4 as uuid } from "uuid";
import { Languages } from "../../Components/Shared/Utilites/DateAndTimeUtilites";
import * as S from "../../StyledComponents/basicStyledComponents";
import { Error } from "../../StyledComponents/warningsStyledComponents";
import { generateLabel, noValueElement } from "../shared/Utils";

interface DateTimePickerComposition {
  RangePicker: React.FunctionComponent<RangePickerProps<Date>>;
}

const DateTimePicker: React.FunctionComponent<any> &
  DateTimePickerComposition = (props) => {
  const { t } = useTranslation();
  const dateFormat = "YYYY-MM-DD HH:mm:ss";
  let id = useMemo(() => uuid(), []);
  if (props.id) {
    id = props.id;
  }

  return (
    <>
      <S.InputWrapper isInTable={!!props.isInTable}>
        {generateLabel(props.label, props.required, id)}
        {props.isReadOnly && (
          <S.ReadOnlyValue>{props.value || noValueElement} </S.ReadOnlyValue>
        )}
        {!props.isReadOnly && (
          <S.InputMargin error={!!props.error}>
            <DatePicker
              {...props}
              showTime
              value={props.value ? moment(props.value, dateFormat) : undefined}
              locale={i18next.language === Languages.pl_PL ? locale : undefined}
            />
          </S.InputMargin>
        )}
        <Error>
          {props.error && (
            <div data-testid={`error_${id}`}>
              {t(`error:${props.error}`, props.error)}
            </div>
          )}
        </Error>
      </S.InputWrapper>
    </>
  );
};

const RangePicker: React.FunctionComponent<RangePickerProps<Date>> = (
  props
) => <RangePicker {...props} />;

DateTimePicker.RangePicker = RangePicker;

export default DateTimePicker;
