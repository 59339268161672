import React from 'react';
import { Table as AntTable, TableProps as AntTableProps } from 'antd';
import './Table.less';
import { PaginationLocale } from 'antd/es/pagination/Pagination';
import i18next from 'i18next';
import { TablePaginationConfig } from 'antd/es/table/interface';
const pl: PaginationLocale = {
  items_per_page: '/ na stronie',
};

const Table: React.FunctionComponent<AntTableProps<any>> = (props) => {
  const pagination: TablePaginationConfig = {
    ...props.pagination,
    locale: i18next.language === 'pl_PL' ? pl : undefined,
    defaultPageSize: 20,
    pageSizeOptions: [20, 50, 100],
    position: ['bottomLeft'],
  };
  return <AntTable bordered {...props} pagination={props.pagination ? pagination : false} />;
};

export default Table;
