import { createContext, useContext, useEffect, useState } from "react";
import {
  useGetAppProfile,
  useGetAppVersion,
  useGetEncryptionPublicKey,
} from "../Login/Login.service";
import { AppProfileOptions } from "./Models/AppProfileOptions";
import { GlobalContextProviderModel } from "./Models/GlobalContextProviderModel";

const GlobalContext = createContext<GlobalContextProviderModel | undefined>(
  undefined
);
export const useGlobalContext = () => {
  const context = useContext(GlobalContext);
  if (!context) {
    throw new Error("out of the global context");
  }
  return context;
};

interface GlobalContextProviderProps {
  children: React.ReactNode;
}

export const GlobalContextProvider: React.FC<GlobalContextProviderProps> = (
  props
) => {
  const [globalContext, setGlobalContext] =
    useState<GlobalContextProviderModel>();

  const [appVersion, setAppVersion] = useState<string | undefined>();

  const onQueryAppProfileSuccess = (appProfile: AppProfileOptions) =>
    setGlobalContext({
      appProfile,
      encryptionKey: undefined,
      appVersion,
    });

  const appProfileQuery = useGetAppProfile(
    !!!globalContext?.appProfile,
    onQueryAppProfileSuccess
  );

  const appVersionQuery = useGetAppVersion(!appVersion, (version) => setAppVersion(version))

  const onEncryptionKeyQuerySuccess = (encryptionKey: string) => {
    if (globalContext) setGlobalContext({ ...globalContext, encryptionKey });
  };

    useEffect(() => {
      if(appVersion === undefined) {
        appVersionQuery.refetch()
      } else {
      setGlobalContext({ ...globalContext as any, appVersion: appVersion })
      }
  }, [appVersion]);

  const encryptionKeyQuery = useGetEncryptionPublicKey(
    onEncryptionKeyQuerySuccess
  );

  useEffect(() => {
    if (
      !appProfileQuery.isLoading &&
      appProfileQuery.data?.data !== AppProfileOptions.openam
    ) {
      encryptionKeyQuery.refetch();
    }
  }, [appProfileQuery.data]);

  return (
    <>
      {globalContext && (
        <GlobalContext.Provider value={globalContext}>
          {!appProfileQuery.isLoading && props.children}
        </GlobalContext.Provider>
      )}
    </>
  );
};
