export enum CompanyDropdownSettingsMode {
  enabled,
  readonly,
}

export interface NavbarState {
  companyDropdownSettings: CompanyDropdownSettingsType;
}

export interface CompanyDropdownSettingsType {
  visible: boolean;
  mode: CompanyDropdownSettingsMode;
  workingContext: "invoices" | "purchases";
  companySelectedName?: string;
}
