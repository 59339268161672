import { Input } from "antd";
import BigNumber from "bignumber.js";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import NumberFormat from "react-number-format";
import { v4 as uuid } from "uuid";
import { maxDigitsBaseMessage } from "../../Components/Invoices/Invoice/InvoiceFormV2/InvoiceFormValidations/InvoiceYupValidator/InvoiceYupValidator";
import * as S from "../../StyledComponents/basicStyledComponents";
import { Error } from "../../StyledComponents/warningsStyledComponents";
import { generateLabel, noValueElement } from "../shared/Utils";
import "./CurrencyFormat.less";
interface Props {
  value: number;
  decimalScale?: number;
  label?: React.ReactNode;
  error?: string;
  maxValue: string;
  onChange?: any;
  name: string;
  id?: string;
  displayType?: "text" | "input";
  isReadOnly?: boolean;
  placeholder?: string;
  isInTable?: boolean;
  required?: boolean;
  refCurrency?: any;
  suffix?: string;
  disabled?: boolean;
  errorInputStyle?: boolean;
  min?: number;
  max?: number;
}

const CurrencyFormat: React.FunctionComponent<Props> = (props) => {
  const { t } = useTranslation();
  const {
    decimalScale,
    label,
    error,
    maxValue = "9999999999999999.99",
    value,
    onChange,
    placeholder,
    isReadOnly,
    name,
    required,
    suffix,
    disabled,
    errorInputStyle,
    min,
    max,
    ...restProps
  } = props;

  let id = useMemo(() => uuid(), []);
  let displayType: "text" | "input";
  if (isReadOnly) {
    displayType = "text";
  } else {
    displayType = restProps.displayType || "input";
  }

  if (restProps.id) {
    id = restProps.id;
  }
  const [currency, setCurrency] = React.useState(value);

  const getErrorMessage = (message: string) => {
    if (message.includes(maxDigitsBaseMessage)) {
      const [baseMessage, max, digitalNumber] = message.split(" ");
      return t(`error:${baseMessage}`, { max, digitalNumber });
    }
    return t(`error:${message}`, message);
  };

  const withValueLimit = ({ value }: any) => {
    const num1 = new BigNumber(maxValue);
    return (
      num1.isGreaterThanOrEqualTo(new BigNumber(value)) || value === ""
    );
  };

  return (
    <div className="currency-format-content">
      <S.InputWrapper isInTable={!!restProps.isInTable}>
        {generateLabel(label, !!required, id)}
        <S.ReadOnlyValueConditionaly readOnly={!!isReadOnly}>
          <>
            <S.InputMargin error={(!!error || errorInputStyle) as any}>
              <NumberFormat
                customInput={Input}
                placeholder={decimalScale === 0 ? "0" : "0,00"}
                value={currency}
                name={name}
                isAllowed={withValueLimit}
                suffix={suffix}
                id={id}
                disabled={disabled}
                decimalScale={decimalScale ?? 2}
                thousandSeparator={" "}
                decimalSeparator=","
                fixedDecimalScale
                isNumericString
                min={min}
                max={max}
                getInputRef={props.refCurrency}
                displayType={displayType}
                onValueChange={(target) => {
                  setCurrency(
                    (target.value === "" ? null : target.value) as any
                  );
                  onChange(target.value);
                }}
              />
            </S.InputMargin>
            {!value && isReadOnly && noValueElement}
          </>
        </S.ReadOnlyValueConditionaly>
        <Error>
          {error && (
            <div data-testid={`error_${id}`}>{getErrorMessage(error)}</div>
          )}
        </Error>
      </S.InputWrapper>
    </div>
  );
};

export default CurrencyFormat;
