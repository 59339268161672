import React from 'react';
import { Tooltip as AntTooltip, TooltipProps as AntTooltipProps } from 'antd';
import './Tooltip.less';
import { Colors } from '../../Shared/Colors';

const Tooltip: React.FunctionComponent<AntTooltipProps> = (props) => {
  const { color, title, ...restPros } = props;
  const properColor = !!color ? color : Colors.White;

  const Title: React.FC = () => {
    // @ts-ignore: Unreachable code error
    return <div className="tooltip-content">{title}</div>;
  };

  return (
    <AntTooltip title={<Title />} overlayClassName={'tooltip-box'} color={properColor} {...restPros}>
      {props?.children}
    </AntTooltip>
  );
};

export default Tooltip;
