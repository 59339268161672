import axios from "../../../Shared/axios";
import { RoleType } from "./Models/RoleType";

export const rolesUrl = "api/authorization/roles";
export const privilegesUrl = "api/authorization/privileges";

export class RoleAndPermissionsService {
  static getRoles() {
    return axios.get<RoleType[]>(`${rolesUrl}`);
  }
  static getPrivileges() {
    return axios.get(`${privilegesUrl}`);
  }

  static deleteRole(id: number) {
    return axios.delete(`${rolesUrl}/${id}`);
  }

  static create(role: RoleType) {
    return axios.post<RoleType>(`${rolesUrl}`, role);
  }

  static update(id: string, role: RoleType) {
    // @ts-ignore: Unreachable code error
    delete role.isActive;
    return axios.put(`${rolesUrl}/${id}`, role);
  }
}
