import { Footer } from "antd/lib/layout/layout";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router";
import { Button, Modal } from "../../../DesignSystem";
import { ModalSizes } from "../../../DesignSystem/Modal/Modal";
import { tKeys } from "../../Constant/tKeys";
import { AppVersion } from "../../Shared/AppVersion/AppVersion";
import { Languages } from "../../Shared/Utilites/DateAndTimeUtilites";
import { useAppProvider } from "../AppProvider";
import { VersionVox as VersionBox, VersionForFooter } from "./AppFooterStyled";

interface AppFooterProps {}

export const AppFooter: React.FC<AppFooterProps> = (props) => {
  const { t, i18n } = useTranslation();
  const location = useLocation();
  const lastPart = location.pathname.split("/").pop();
  const isInInvoice = lastPart
    ? ["new", "edit", "view"].includes(lastPart)
    : false;

  const [personalDataVisible, setPersonalDataVisible] = useState(false);
  const { personalDataLinkVisible } = useAppProvider();

  const getFullInfoOfPersonalData = () => {
    return i18n.language === Languages.pl_PL ? (
      <a href="https://www.pwc.pl/pl/o-nas/polityka-prywatnosci/informacja-o-przetwarzaniu-danych-osobowych-dla-uzytkownikow-aplikacji-pwc.html">
        {t(tKeys.privacyPolicy, tKeys.privacyPolicyText)}
      </a>
    ) : (
      <a href="https://www.pwc.pl/en/o-nas/privacy-policy/information-on-processing-of-personal-data-for-pwc-applications-users.html">
        {t(tKeys.privacyPolicy, tKeys.privacyPolicyText)}
      </a>
    );
  };
  return (
    <>
      <Footer>
        <div
          className={
            isInInvoice ? "text-content-in-invoice-form" : "text-content"
          }
        >
          {t(tKeys.copyright, tKeys.copyright, {
            year: new Date().getFullYear(),
          })}
          <a href="https://www.pwc.com/gx/en/about/corporate-governance/network-structure.html">
            www.pwc.com/structure
          </a>{" "}
          {t(tKeys.copyrightPartTwo, tKeys.copyrightPartTwo)}
          <VersionForFooter personalDataLinkVisible={personalDataLinkVisible}>
            {personalDataLinkVisible && (
              <div
                onClick={() => setPersonalDataVisible(true)}
                className="information-on-the-processing-of-personal-data"
              >
                {t(
                  tKeys.informationOnTheProcessingOfPersonalData,
                  tKeys.informationOnTheProcessingOfPersonalDataText
                )}
              </div>
            )}
            <VersionBox>
              <AppVersion />
            </VersionBox>
          </VersionForFooter>
        </div>
      </Footer>
      <Modal
        open={personalDataVisible}
        hideCancelButton={false}
        width={ModalSizes.LARGE}
        onCancel={() => setPersonalDataVisible(false)}
        footer={
          <div className="modal-footer-processing-personal-data">
            <Button
              type="primary"
              onClick={() => {
                setPersonalDataVisible(false);
              }}
            >
              {t(tKeys.button.close, tKeys.button.closeText)}
            </Button>
          </div>
        }
        title={t(
          tKeys.informationOnTheProcessingOfPersonalData,
          tKeys.informationOnTheProcessingOfPersonalDataText
        )}
      >
        <div className="processing-personal-data-modal">
          <div className="processing-personal-data-part">
            {t(
              tKeys.processingPersonalDataPart1,
              tKeys.processingPersonalDataPart1Text
            )}
          </div>
          <div className="processing-personal-data-part">
            {t(
              tKeys.processingPersonalDataPart2,
              tKeys.processingPersonalDataPart2Text
            )}
          </div>
          <div className="processing-personal-data-part">
            {t(
              tKeys.processingPersonalDataPart3,
              tKeys.processingPersonalDataPart3Text
            )}
          </div>
          <div className="processing-personal-data-part">
            {t(
              tKeys.processingPersonalDataPart4,
              tKeys.processingPersonalDataPart4Text
            )}{" "}
            {getFullInfoOfPersonalData()}.
          </div>
        </div>
      </Modal>
    </>
  );
};
