import * as React from "react";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Button, Input, errorMessage } from "../../DesignSystem";
import { AppIcons } from "../../Shared/Icons/AppIcons";
import logo from "../../Shared/PwC_Outline_Logo_White.png";
import image from "../../Shared/loginImage.jpeg";
import * as S from "../../StyledComponents/basicStyledComponents";
import { LogoutStates } from "../App/Components/TokenRefresher/SessionTimerManager";
import { tKeys } from "../Constant/tKeys";
import { useGlobalContext } from "../GlobalContextProvider/GlobalContextProvider";
import { AppProfileOptions } from "../GlobalContextProvider/Models/AppProfileOptions";
import LanguageSwitcher from "../Navbar/Components/LanguageSwitcher/LanguageSwitcher";
import { AppVersion } from "../Shared/AppVersion/AppVersion";
import { useSetTitle } from "../SharedHooks/useSetTitle";
import { LoginData } from "../UserContextProvider/Models/LoginData";
import { useUserContext } from "../UserContextProvider/UserContextProvider";
import "./Login.less";

export const getEnvPath = (env: string) => {
  switch (env) {
    case "/eFaktury-test/":
      return "/eFaktury-test/";
    case "/eFaktury-RC/":
      return "/eFaktury-RC/";
    default:
      return "/";
  }
};

export const Login: React.FC = () => {
  useSetTitle("eFaktury");
  const { t } = useTranslation();
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<LoginData>();

  const globalContext = useGlobalContext();
  const userContext = useUserContext();

  if (sessionStorage.getItem("openAm") === "afterRedirect") {
    sessionStorage.setItem("openAm", "beforeRedirect");
    errorMessage("accessDenied");
  }

  const loginWithOpenAM = () => {
    const origin = window.location.origin;
    const path = window.location.pathname;

    const openAM = `${origin}${getEnvPath(
      path
    )}api/authentication/authenticate/openam`;
    window.location.replace(openAM);
  };

  const logoutState = localStorage.getItem("logoutState");
  const [endSessionText] = useState(logoutState === LogoutStates.endSession);

  React.useEffect(() => {
    setTimeout(() => {
      const logoutState = localStorage.getItem("logoutState");
      if (logoutState === LogoutStates.endSession) {
        localStorage.setItem("logoutState", LogoutStates.logOut);
      }
    }, 500);
  });

  return (
    <>
      <S.Content>
        <div className="language-switcher-login">
          <LanguageSwitcher />
        </div>
      </S.Content>
      <div className="black-background"></div>
      <div className="login-content">
        <img className="background-picture" src={image} />
        <div className="login-form">
          <div className="logo">
            {!endSessionText && (
              <img src={logo} alt="Logo" className="logo-image" />
            )}
          </div>
          <div className="login-form-wrapper">
            <div className="login-form-content">
              {!endSessionText && (
                <>
                  <div className="welcome">
                    {t(tKeys.welcome, tKeys.welcomeText)}
                  </div>
                  <div className="name">e-Invoicing Tool</div>
                  <div className="login">
                    {t(tKeys.loginWorld, tKeys.loginWorldText)}
                  </div>
                </>
              )}
              {endSessionText && (
                <div className="login-after-end-session-box ">
                  <AppIcons.Revert
                    width="80px"
                    height="80px"
                    viewBox="0 0 80 80"
                  />
                  <div className="login-after-end-session-desc">
                    {t(tKeys.endSessions.loginForm.desc)}
                  </div>
                </div>
              )}

              <div className="inputs">
                {globalContext.appProfile !== AppProfileOptions.openam && (
                  <form
                    onSubmit={handleSubmit((data: LoginData) =>
                      userContext.onLoggedIn(data)
                    )}
                  >
                    <Controller
                      name={"username"}
                      control={control}
                      rules={{ required: "required" }}
                      render={({ field }: any) => {
                        const { ref, ...restField } = field;
                        return (
                          <Input
                            {...restField}
                            label={t(tKeys.username, tKeys.usernameText)}
                            inputRef={ref}
                            required
                            error={errors?.username?.message}
                            data-testid="login"
                          />
                        );
                      }}
                    />
                    <Controller
                      name={"password"}
                      control={control}
                      rules={{ required: "required" }}
                      render={({ field }: any) => {
                        const { ref, ...restField } = field;
                        return (
                          <Input
                            {...restField}
                            type="password"
                            inputRef={ref}
                            required
                            label={t(tKeys.password, tKeys.passwordText)}
                            error={errors?.password?.message}
                            data-testid="password"
                          />
                        );
                      }}
                    />
                    <Button
                      className="login-standard-button"
                      htmlType="submit"
                      type="default"
                      loading={userContext.isLoginLoading}
                      data-testid="login-button"
                    >
                      {t("login.login")}
                    </Button>
                  </form>
                )}

                {globalContext.appProfile === AppProfileOptions.openam && (
                  <Button
                    className="login-with-openAM"
                    htmlType="submit"
                    type="default"
                    onClick={() => {
                      loginWithOpenAM();
                    }}
                  >
                    {t("login.login")}
                  </Button>
                )}
              </div>
            </div>
          </div>
          <div className="version">
            <AppVersion />
          </div>
        </div>
      </div>
    </>
  );
};
