import * as yup from "yup";

const maxLengthMessage = "maxlength";
export const invalidMessage = "invalid";

declare module "yup" {
  interface StringSchema {
    requiredWithProperMessageCustom(): StringSchema;
    max256(): StringSchema;
    max512(): StringSchema;
    max3500(): StringSchema;
    max56(): StringSchema;
    max36(): StringSchema;
    max20(): StringSchema;
    max9(): StringSchema;
    max50(): StringSchema;
    max12(): StringSchema;
    max32(): StringSchema;
    max13(): StringSchema;
    subjectPhone(): StringSchema;
    subjectEmail(): StringSchema;
    tin(): StringSchema;
    date(): StringSchema;
    ksefNo(): StringSchema;
    subjectPrefix(): StringSchema;
    internalId(): StringSchema;
    otherTaxId(): StringSchema;
    euVatNumber(): StringSchema;
    matchesWithMessage(regex: RegExp): StringSchema;
    maximumNumberOfDigits(
      maxDigits: number,
      maxDigitalNumber: number
    ): StringSchema;
    maximum18DigitsAnd2Digits(): StringSchema;
  }
}

yup.addMethod(yup.string, "requiredWithProperMessageCustom", function () {
  return this.required("required");
});

yup.addMethod(yup.string, "max256", function () {
  return this.max(256, maxLengthMessage);
});

yup.addMethod(yup.string, "max512", function () {
  return this.max(512, maxLengthMessage);
});

yup.addMethod(yup.string, "max3500", function () {
  return this.max(3500, maxLengthMessage);
});

yup.addMethod(yup.string, "max56", function () {
  return this.max(56, maxLengthMessage);
});

yup.addMethod(yup.string, "max36", function () {
  return this.max(36, maxLengthMessage);
});

yup.addMethod(yup.string, "max32", function () {
  return this.max(32, maxLengthMessage);
});

yup.addMethod(yup.string, "max20", function () {
  return this.max(20, maxLengthMessage);
});

yup.addMethod(yup.string, "max9", function () {
  return this.max(9, maxLengthMessage);
});
yup.addMethod(yup.string, "max50", function () {
  return this.max(50, maxLengthMessage);
});
yup.addMethod(yup.string, "max13", function () {
  return this.max(13, maxLengthMessage);
});

yup.addMethod(yup.string, "max12", function () {
  return this.max(12, maxLengthMessage);
});

yup.addMethod(yup.string, "tin", function () {
  return this.min(10, "tinLength")
    .max(10, "tinLength")
    .matches(/^$|^[1-9]((\d[1-9])|([1-9]\d))\d{7}$/, invalidMessage);
});

yup.addMethod(yup.string, "subjectEmail", function () {
  return this.email("invalidEmail").max(256, maxLengthMessage);
});

yup.addMethod(yup.string, "subjectPhone", function () {
  return this.max(16, maxLengthMessage);
});

yup.addMethod(yup.string, "date", function () {
  return this.matches(
    /^\d{4}-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01])$/,
    "dateFormatIncorrect"
  );
});

yup.addMethod(yup.string, "ksefNo", function () {
  return this.matches(
    /^([1-9]((\d[1-9])|([1-9]\d))\d{7}|M\d{9}|[A-Z]{3}\d{7})-(20[2-9][0-9]|2[1-9][0-9]{2}|[3-9][0-9]{3})(0[1-9]|1[0-2])(0[1-9]|[1-2][0-9]|3[0-1])-([0-9A-F]{6})-?([0-9A-F]{6})-([0-9A-F]{2})$/,
    invalidMessage
  );
});

yup.addMethod(yup.string, "subjectPrefix", function () {
  return this.matches(/^[A-Z]{2}$/, invalidMessage);
});

yup.addMethod(yup.string, "otherTaxId", function () {
  return this.max50().matches(/[a-zA-Z0-9]{1,50}/, invalidMessage);
});

yup.addMethod(yup.string, "internalId", function () {
  return this.matches(
    /^[1-9]((\d[1-9])|([1-9]\d))\d{7}-\d{5}$/,
    invalidMessage
  );
});

yup.addMethod(yup.string, "euVatNumber", function () {
  return this.max12().matches(/(\d|[A-Z]|\+|\*){1,12}/, invalidMessage);
});

yup.addMethod(yup.string, "matchesWithMessage", function (regex: RegExp) {
  return this.matches(regex, invalidMessage);
});

yup.addMethod(
  yup.string,
  "maximum18DigitsAnd2Digits",
  function (regex: RegExp) {
    return this.maximumNumberOfDigits(18, 2);
  }
);

export const maxDigitsBaseMessage = "maxDigits";
yup.addMethod(
  yup.string,
  "maximumNumberOfDigits",
  function (maxDigits: number, maxDigitalNumber: number) {
    return this.test(
      maxDigitsBaseMessage,
      `${maxDigitsBaseMessage} ${maxDigits} ${maxDigitalNumber}`,
      (value) => {
        const numberString = value;
        if (numberString) {
          const numberQuantity = numberString.replace(/[.\s]/g, "").length;
          return numberQuantity <= maxDigits;
        }
        return true;
      }
    );
  }
);

export default yup;
