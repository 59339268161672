import styled from "styled-components";
import { Colors } from "../../../Shared/Colors";

export const ModuleDisabledBox = styled.div`
  background-color: ${Colors.Lightest};
  border-radius: 10px;
  padding: 40px;
  margin-top:20px;
  text-align: center;
`;

export const IconBox = styled.div`
  background-color: ${Colors.Lighter};
  width: 150px;
  height: 150px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 40px;
  position: relative;
  left: 50%;
  transform: translate(-50%, 0);
  svg {
    color: ${Colors.Neutral17};
    width: 180px;
    height: 122px;
  }
`;

export const TitleText = styled.div`
  font-weight: 500;
  font-size: 28px;
  line-height: 34px;
  text-align: center;
  margin-bottom: 25px;
  color: ${Colors.Darker};
`;

export const InfoText = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: ${Colors.Gray};
  width: 746px;
  position: relative;
  left: 50%;
  transform: translate(-50%, 0);
  margin-bottom: 80px;
`;
