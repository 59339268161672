import styled, { css } from "styled-components";
import { Colors } from "../../../Shared/Colors";

export const VersionForFooter = styled.div(
  (props: { personalDataLinkVisible: boolean }) => css`
    display: flex;
    justify-content: ${props.personalDataLinkVisible ? "space-between" : "end"};
    align-items: center;
  `
);

export const VersionVox = styled.div`
  height: 14px;
  color: ${Colors.Gray};
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 14px;
  text-align: right;
`;
